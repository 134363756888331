import { MEMBER_SERVICE_STATUS } from "@/apps/common/constants";
import { formatDate } from "@/apps/common/helpers/date";
import { MemberServiceType } from "@/apps/common/types/appointment-types";
import { useMemo } from "react";
import { Button, ListGroup } from "react-bootstrap";

type Props = {
  memberServices: MemberServiceType[];
  onChange: (memberServiceId: number) => void;
};

function ServiceListItem({
  memberService,
  onClick
}: {
  memberService: MemberServiceType;
  onClick: () => void;
}) {
  const appointmentTime = useMemo(() => {
    let returnVal = "";
    if (memberService.status === MEMBER_SERVICE_STATUS.APPOINTMENT_SCHEDULED) {
      if (memberService.appointment?.timeSlot?.startDate)
        returnVal = formatDate(memberService.appointment?.timeSlot?.startDate);
    }

    return returnVal;
  }, [memberService]);

  const sessionLink = useMemo(() => {
    let meetingLink;

    if (memberService.status === MEMBER_SERVICE_STATUS.APPOINTMENT_SCHEDULED) {
      meetingLink = memberService?.appointment?.meetingJoinLink;
    }

    console.debug("meetingLink - " + meetingLink);
    return meetingLink;
  }, [memberService]);

  const status = useMemo(() => {
    if ((memberService.status === MEMBER_SERVICE_STATUS.SURVEY_COMPLETED) || (memberService.status === MEMBER_SERVICE_STATUS.SURVEY_NOT_REQUIRED)){
      return "Select this session to schedule an appointment with a counselor.";
    } else if (
      memberService.status === MEMBER_SERVICE_STATUS.SURVEY_STARTED ||
      memberService.status === MEMBER_SERVICE_STATUS.ONBOARDED
    ) {
      return "Select this session to complete survey and schedule an appointment with a counselor.";
    } else if ((
      memberService.status === MEMBER_SERVICE_STATUS.APPOINTMENT_SCHEDULED
    ) && (memberService?.serviceType?.name !== "Health and Wellness Coaching")) {
      if (memberService.memberReport?.status !== "complete") {
        return "Please select this session to finish your survey before your appointment";
      }
    } else if (
      memberService.status === MEMBER_SERVICE_STATUS.APPOINTMENT_RESCHEDULE
    ) {
      return "Select this session to schedule an appointment";
    }
    else if (memberService.status === MEMBER_SERVICE_STATUS.APPOINTMENT_STATUS_PENDING){
      return "You appointment is pending confirmation by the counselor."
    }

    return memberService.status;
  }, [memberService]);

  const completionDate = useMemo(() => {
    if (
      (memberService.status === "complete" ||
        memberService.status === "archived") &&
      memberService?.providerReport?.updatedAt
    ) {
      return formatDate(
        memberService?.providerReport?.submissionDate ??
          memberService?.providerReport?.updatedAt
      );
    }
  }, [memberService]);

  return (
    <ListGroup.Item
      key={memberService.id}
      className="d-md-flex flex-row align-items-center"
      action
      onClick={onClick}
    >
      <dl className="flex-fill">
        <h4 className="my-3">{memberService.serviceType?.name}</h4>

        <dt className="col-sm-2">Status</dt>
        {status ? <dd className="col-sm-10">{status}</dd> : null}

        {appointmentTime != "" && (
          <>
            <dt className="col-sm-2">Appointment Time:</dt>
            <dd className="col-sm-10">{appointmentTime}</dd>
          </>
        )}

        {sessionLink && (
          <>
            <dt className="col-sm-2">Session Link:</dt>
            <dd className="col-sm-10">
              <a href={sessionLink} target="_blank">
                <Button className="btn btn-md btn-primary rounded-pill">
                  Join Session
                </Button>
              </a>
            </dd>
          </>
        )}

        {completionDate && (
          <>
            <dt className="col-sm-2">Completion Date:</dt>
            <dd className="col-sm-10">{completionDate}</dd>
          </>
        )}
      </dl>
      <Button>Select this session</Button>
    </ListGroup.Item>
  );
}

export default function ServiceSelector({ memberServices, onChange }: Props) {
  return (
    <div className="d-flex flex-column">
      <ListGroup className="my-3">
        {memberServices.map((memberService) => {
          return (
            <ServiceListItem
              key={memberService.id}
              memberService={memberService}
              onClick={() => onChange(memberService.id)}
            />
          );
        })}
      </ListGroup>
    </div>
  );
}
