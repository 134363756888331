import { api } from "@/apps/common/api-client";
import CPTCodeSelector from "@/apps/common/components/CPTCodeSelector";
import PageTitle from "@/apps/common/components/PageTitle";
import { APPOINTMENT_STATUS } from "@/apps/common/constants";
//import { getFullName } from "@/apps/common/helpers";
import { formatDate } from "@/apps/common/helpers/date";
import {
  AppointmentType,
  CPTCodeType
} from "@/apps/common/types/appointment-types";
import Form from "@/modules/react-jsonschema-form-bootstrap";
import { ErrorSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useDocumentVisibility, useRequest } from "ahooks";
import clsx from "clsx";
import { default as FeatherIcon } from "feather-icons-react";
import { pickBy } from "lodash-es";
import Markdown from "markdown-to-jsx";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Accordion,
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Modal,
  Row,
  Spinner
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import MemberSessionDetails from "../../components/MemberSessionDetails";
import styles from "./AppointmentReport.module.css";
import AppointmentTranscripts from "./AppointmentTranscripts";
import ReportPreview from "./ReportPreview";
import ClearableTextareaWidget from "@/apps/common/components/form-widgets/ClearableTextareaWidget";
import AppointmentDiagnosisAid from "./AppointmentDiagnosisAid";
import ProgressReportForm from "@/apps/common/components/ProgressReport";

const clearLabelErrors = () => {
  document.querySelectorAll(`label.text-danger`).forEach((element) => {
    element.classList.remove("text-danger");
  });
};

function processValidationErrors(errorSchema: ErrorSchema<any>) {
  clearLabelErrors();

  let isValid = !errorSchema;

  if (!isValid) {
    for (const key in errorSchema) {
      const stepErrorSchema = pickBy(errorSchema[key], (value, key) => {
        if (key === "__errors") {
          return false;
        }

        const valueArray = (value as typeof errorSchema)?.__errors as string[];
        if (!valueArray?.length) {
          return true;
        }

        return !(
          valueArray?.length === 1 &&
          valueArray?.[0] === "must be equal to one of the allowed values"
        );
      });

      const groupParent =
        document
          .querySelector(`#root_${key}__title`)
          ?.closest(".form-group.field-object") || document;

      const errorFields = Object.keys(stepErrorSchema) as string[];
      errorFields.forEach((field) => {
        const element = groupParent.querySelector(`label[for*="${field}"]`);
        if (element) {
          element.classList.add("text-danger");
        }
      });
    }

    const element = document.querySelector(`label.text-danger`);
    if (!element) {
      isValid = true;
    }
  }

  if (!isValid) {
    setTimeout(() => {
      const element = document.querySelector(`label.text-danger`);
      const y = element!.getBoundingClientRect().top + window.scrollY - 140;
      window.scrollTo({ top: y, behavior: "smooth" });
    }, 100);
  }

  return isValid;
}

const LoaderSpinner = () => (
  <Spinner
    style={{
      height: 20,
      width: 20,
      marginTop: 4,
      fontSize: 10
    }}
  />
);

function AppointmentConfirmationModal({
  appointment,
  refreshAppointment
}: {
  appointment: AppointmentType;
  refreshAppointment: () => Promise<unknown>;
}) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (appointment?.status === APPOINTMENT_STATUS.PENDING) {
      setConfirmation(true);
    }
  }, [appointment?.status]);

  const confirmAppointment = async () => {
    if (!appointment) {
      return;
    }

    try {
      setConfirmLoading(true);
      await api.appointments.respond(
        appointment.id,
        APPOINTMENT_STATUS.CONFIRMED
      );
      await refreshAppointment();
      setConfirmLoading(false);
      setConfirmation(false);

      navigate("/appointments");
    } catch (err) {
      console.log(err);
      setConfirmLoading(false);
    }
  };

  const cancelAppointment = async () => {
    try {
      await refreshAppointment();
      setConfirmLoading(true);

      await api.appointments.respond(
        appointment!.id,
        APPOINTMENT_STATUS.DECLINED
      );

      setConfirmLoading(false);
      setConfirmation(false);
      navigate("/appointments");
    } catch (err) {
      console.log(err);
      setConfirmation(false);
    }
  };

  const hideConfirmation = () => {
    setConfirmation(false);
    navigate("/appointments");
  };

  const { date: appointmentDate, endDate: appointmentEndDate } = useMemo(() => {
    if (!appointment) {
      return {};
    }

    const timeSlot = appointment.timeSlot;
    return {
      date: formatDate(timeSlot.startDate),
      endDate: formatDate(timeSlot.endDate)
    };
  }, [appointment]);

  return (
    <Modal onHide={() => hideConfirmation()} show={confirmation} centered>
      <Modal.Header closeButton>
        <h4 className="modal-title block">Appointment confirmation</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="justify-content-center">
          <p className="sub-header">
            <b>Start Time:</b> {appointmentDate}
            <br />
            <b>End Time:</b> {appointmentEndDate}
          </p>
        </div>
        <p>Please confirm your availability for this time.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={confirmLoading}
          onClick={confirmAppointment}
          className="w-100"
        >
          {confirmLoading ? "Loading..." : "Confirm"}
        </Button>

        <Button
          disabled={confirmLoading}
          onClick={cancelAppointment}
          className="w-100"
        >
          {confirmLoading ? "Loading..." : "Decline"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const deepCompare = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) {
    return true;
  }
  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return false;
  }
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  for (const key in obj1) {
    if (["children"].includes(key)) {
      continue;
    }

    if (!deepCompare(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
};

const FixedForm = memo(Form, (prevProps: any, nextProps: any) => {
  return deepCompare(prevProps, nextProps);
});

export default function AppointmentReport() {
  const { id } = useParams();
  const formRef: any = useRef();
  const [defaultValues, setDefaultValues] = useState({});
  const [loader, setLoader] = useState(false);

  const [formSaving, setFormSaving] = useState(false);
  const [draftSaving, setDraftSaving] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);

  const { data: appointment, runAsync: fetchMemberAppointmentDetail } =
    useRequest(() => api.appointments.getById(Number(id)), { manual: true });

  const memberService = appointment?.memberService;
  const providerReport = memberService?.providerReport;
  const evaluationTemplate = providerReport?.template;
  const providerReportStatus = providerReport?.status;

  const memberReport = memberService?.memberReport;
  const memberReportStatus = memberReport?.status;

  const [selectedCptCodes, setSelectedCptCodes] = useState<
    CPTCodeType[] | undefined
  >(providerReport?.cpt_codes);
  useEffect(() => {
    if (!providerReport) {
      return;
    }

    setSelectedCptCodes(providerReport.cpt_codes);
  }, [providerReport]);

  let stringified = JSON.stringify(providerReport?.template?.template);

  console.debug('APPOINTMENT - ' + JSON.stringify(appointment?.progress_report))

  if (stringified?.includes("<member name>")) {
    console.debug(stringified);
    const memberName = memberService?.user
      ? memberService?.user.firstName + " " + memberService?.user.lastName
      : "ERROR";

    stringified = stringified.replace("<member name>", memberName);
    if (providerReport)
      providerReport.template.template = JSON.parse(stringified);

    console.debug(
      "Provider Report " + typeof providerReport?.template?.template
    );
  }

  const {
    loading: autoSaving,
    runAsync: saveDraftRequest,
    run: startDraftPolling,
    cancel: stopDraftPolling
  } = useRequest(
    () => {
      if (!providerReport) {
        return;
      }

      const formData = (formRef as any).current?.state?.formData || {};
      // setDefaultValues(formData);
      const data = {
        response: formData,
        status: "draft",
        cpt_codes: selectedCptCodes?.map((code) => code.id)
      };

      return api.evaluations.updateReport(providerReport!.id, data) as any;
    },
    { manual: true, pollingInterval: 5000 }
  );

  const documentVisibility = useDocumentVisibility();

  const saveDraft = useCallback(
    async (validate = false) => {
      try {
        setDraftSaving(true);

        await saveDraftRequest();
        await fetchMemberAppointmentDetail();

        if (validate) {
          const { errorSchema } = validator.validateFormData(
            formRef.current.state.formData,
            evaluationTemplate?.template
          );

          setDraftSaving(false);

          return processValidationErrors(errorSchema);
        }

        setDraftSaving(false);
      } catch (err) {
        console.log(err);
        setDraftSaving(false);
      }
    },
    [
      evaluationTemplate?.template,
      fetchMemberAppointmentDetail,
      saveDraftRequest
    ]
  );

  const isDueAppointment = useMemo(() => {
    return appointment?.status === APPOINTMENT_STATUS.DUE;
  }, [appointment?.status]);

  const startEditing = useCallback(() => {
    console.debug("startEditing");

    setEditEnabled(true);
    startDraftPolling();
  }, [startDraftPolling]);

  const refreshAppointmentDetails = useCallback(() => {
    setLoader(true);
    fetchMemberAppointmentDetail()
      .then((appointment) => {
        setDefaultValues(appointment.memberService.providerReport?.response);
        setLoader(false);
      })
      .catch(() => setLoader(false));
  }, [fetchMemberAppointmentDetail]);

  useEffect(() => {
    if (documentVisibility === "visible") {
      refreshAppointmentDetails();
    }
  }, [refreshAppointmentDetails, documentVisibility]);

  useEffect(() => {
    refreshAppointmentDetails();
  }, [setSelectedCptCodes]);

  useEffect(() => {
    if (editEnabled && documentVisibility === "hidden") {
      setEditEnabled(false);
      saveDraft().then(() => {
        refreshAppointmentDetails();
        stopDraftPolling();
      });
    }
  }, [
    documentVisibility,
    editEnabled,
    refreshAppointmentDetails,
    saveDraft,
    stopDraftPolling
  ]);

  const onSubmit = async () => {
    if (!memberService) {
      return;
    }
    if (!selectedCptCodes) {
      console.debug("Select a Diagnosis code");
    }

    stopDraftPolling();

    try {
      const formData = (formRef as any).current?.state?.formData || {};

      setFormSaving(true);

      const data = {
        response: formData,
        status: "complete"
      };
      await api.evaluations.updateReport(providerReport!.id, data);

      await fetchMemberAppointmentDetail();
      setFormSaving(false);
    } catch (err) {
      console.log(err);
      setFormSaving(false);
    }
  };

  const rateSummary = useCallback(
    async (rating: number) => {
      await api.appointments.rateSummary(
        appointment!.transcriptSummary!.id,
        rating
      );
      refreshAppointmentDetails();
    },
    [appointment, refreshAppointmentDetails]
  );

  const { data: cptCodeData } = useRequest(() => api.common.fetchCPTCodes(), {
    cacheKey: "cpt-codes"
  });

  const handleCodeSelection = useCallback(
    (code: string) => {
      console.debug("handleCodeSelection", code);

      const codeFromDatabase = cptCodeData?.find(
        (cptCode) => cptCode.code === code
      );
      if (codeFromDatabase) {
        setSelectedCptCodes([codeFromDatabase]);
      }
    },
    [cptCodeData]
  );

  const completeProgressNote = () => {
    console.debug("Mark Progress Note as complete");

    if (appointment?.progress_report?.id) {
      const data = {
        status: "complete"
      };

      api.progressReport
        .update(appointment?.progress_report?.id, data)
        .then((res) => {
          console.debug(`Progress Note Completed`);
          fetchMemberAppointmentDetail();
        });
    }
  };

  const formComponent = evaluationTemplate ? (
    <FixedForm
      className={
        providerReportStatus === "complete" ? "provider-form-report" : ""
      }
      widgets={{
        clearableTextarea: ClearableTextareaWidget
      }}
      disabled={!editEnabled || providerReportStatus === "complete"}
      showErrorList={false}
      formData={defaultValues}
      ref={formRef}
      schema={evaluationTemplate!.template}
      uiSchema={evaluationTemplate!.uiSchema}
      validator={validator}
      noHtml5Validate={true}
    >
      <div />
    </FixedForm>
  ) : null;

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: "Appointment report", path: "/" }]}
        title={"Session Details"}
      />

      {appointment ? (
        <AppointmentConfirmationModal
          appointment={appointment}
          refreshAppointment={fetchMemberAppointmentDetail}
        />
      ) : null}

      {loader ? (
        <div className="text-center p-4 mt-4">
          <Spinner
            style={{ width: 100, height: 100 }}
            animation="border"
            variant="info"
          />
        </div>
      ) : null}

      {appointment ? (
        <>
          <Card>
            <Card.Body>
              <MemberSessionDetails
                appointment={appointment}
                showActions={true}
              />
            </Card.Body>
          </Card>
          {memberService?.serviceType?.name !==
            "Health and Wellness Peer Support" &&
          memberService?.serviceType?.name !== "Health and Wellness Coaching" &&
          memberReportStatus !== "complete" ? (
            <Alert variant="danger">
              Member has not completed the survey yet.
            </Alert>
          ) : null}

          <Row>
            <Col>
              {memberService?.serviceType?.name !==
                "Health and Wellness Coaching" &&
              appointment?.transcriptSummary?.summary ? (
                <Accordion className="mb-3">
                  <Accordion.Item eventKey="summary">
                    <Accordion.Header as={"h4"}>
                      Appointment Summary
                    </Accordion.Header>
                    <Accordion.Body>
                      <Markdown>
                        {appointment.transcriptSummary?.summary}
                      </Markdown>
                      <ButtonGroup
                        className="me-2"
                        aria-label="Rate the summary"
                      >
                        <Button
                          variant="secondary"
                          active={
                            appointment.transcriptSummary?.summaryRating === 1
                          }
                          onClick={() => rateSummary(1)}
                        >
                          <FeatherIcon icon={"thumbs-up"} />
                        </Button>
                        <Button
                          variant="secondary"
                          active={
                            appointment.transcriptSummary?.summaryRating === -1
                          }
                          onClick={() => rateSummary(-1)}
                        >
                          <FeatherIcon icon={"thumbs-down"} />
                        </Button>
                      </ButtonGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ) : null}
            </Col>
            <AppointmentDiagnosisAid
              appointmentId={Number(id)}
              onDiagnosisCodeSelected={handleCodeSelection}
            />
            {/* {["started", "ended"].includes(appointment.meetingStatus ?? "") ? (
                <AppointmentTranscripts appointmentId={Number(id)} />
              ) : null} */}
          </Row>

          {memberService?.serviceType?.name ===
            "Health and Wellness Coaching" ||
          memberService?.serviceType?.name ===
            "Health and Wellness Peer Support" ? (
            <Card>
              <Card.Header>Progress Report Notes</Card.Header>
              <Card.Body>
                <ProgressReportForm
                  mode={
                    appointment?.progress_report?.status === "draft"
                      ? "edit"
                      : "preview"
                  }
                  appointment={appointment}
                />
              </Card.Body>
              <Card.Footer>
                {appointment?.progress_report?.status === "draft" ? (
                  <Button
                    onClick={() => {
                      completeProgressNote();
                    }}
                  >
                    Complete Progress Report Note
                  </Button>
                ) : null}
              </Card.Footer>
            </Card>
          ) : null}

          {![
            "Health and Wellness Coaching",
            "Health and Wellness Peer Support"
          ].includes(
            memberService?.serviceType?.name
              ? memberService?.serviceType?.name
              : ""
          ) ? (
            <>
              <Accordion className="mb-3" defaultActiveKey={"report"}>
                <Accordion.Item eventKey="report">
                  <Accordion.Header as={"h4"}>
                    Appointment Report
                  </Accordion.Header>
                  <Accordion.Body>
                    {providerReportStatus !== "complete" &&
                    editEnabled === false ? (
                      <>
                        <Button
                          onClick={() => startEditing()}
                          className="mb-2"
                          disabled={isDueAppointment == false}
                        >
                          Edit Report
                        </Button>

                        {!isDueAppointment && (
                          <div>
                            <p>
                              You will be able to edit the report once the
                              appointment is due.
                            </p>
                          </div>
                        )}
                      </>
                    ) : null}
                    <CPTCodeSelector
                      disabled={
                        !editEnabled || providerReportStatus === "complete"
                      }
                      value={selectedCptCodes}
                      onChange={setSelectedCptCodes}
                    />
                    <Card>
                      <Card.Body>
                        {formComponent}
                        <div className="mb-2 mt-2 text-center d-grid col-12 gap-2">
                          {providerReportStatus !== "complete" ? (
                            <Button
                              disabled={draftSaving}
                              onClick={() => saveDraft()}
                              variant={"outline-primary"}
                            >
                              {draftSaving ? <LoaderSpinner /> : "Save Draft"}
                            </Button>
                          ) : null}
                          {selectedCptCodes ? (
                            <ReportPreview
                              disabled={
                                selectedCptCodes.length > 0 ? false : true
                              }
                              loading={formSaving}
                              report={providerReport}
                              saveDraft={saveDraft}
                              submitReport={onSubmit}
                            />
                          ) : null}
                        </div>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {providerReportStatus !== "complete" ? (
                <Button
                  className={clsx("shadow-lg", styles.floatingButton)}
                  onClick={() => saveDraft()}
                  variant="outline-primary"
                >
                  {autoSaving || draftSaving ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <>
                      <FeatherIcon icon="save" />
                      <div>Save Draft</div>
                    </>
                  )}
                </Button>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
}
