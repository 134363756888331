import { FormInput, VerticalForm } from "@/apps/common/components/";
import { useTitle } from "@/apps/common/helpers/useTitle";
import AuthLayout from "@/apps/common/pages/auth/AuthLayout";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import { yupResolver } from "@hookform/resolvers/yup";
import FeatherIcons from "feather-icons-react";
import { useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useLocation } from "react-router-dom";
import * as yup from "yup";

interface UserData {
  email: string;
  password: string;
}

/* bottom links */
const BottomLink = () => {
  const { t } = useTranslation();

  return (
    <Row className="mt-3">
      <Col xs={12} className="text-center">
        <p className="text-muted">
          {t("Don't have an account?")}{" "}
          <Link to={"/auth/register"} className="text-primary fw-bold ms-1">
            {t("Sign Up")}
          </Link>
        </p>
      </Col>
    </Row>
  );
};

export default function AdminLogin() {
  const { t } = useTranslation();
  const { user, loggedIn, login } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useTitle("Login");

  /*
    form validation schema
    */
  const schemaResolver = yupResolver(
    yup.object().shape({
      email: yup.string().required(t("Please enter Email")),
      password: yup.string().required(t("Please enter Password")),
      checkbox: yup.bool().oneOf([true])
    })
  );

  /*
    handle form submission
    */
  const onSubmit = async (formData: UserData) => {
    setLoading(true);
    try {
      setError(null);
      await login({ payload: formData, type: "member-impersonate" });
    } catch {
      setError("Invalid email or password");
    } finally {
      setLoading(false);
    }
  };

  const location = useLocation();
  const redirectUrl = location?.search?.slice(6) || "/";

  const loginText = t("Enter your email address and password to continue.");

  return (
    <>
      {loggedIn || user ? <Navigate to={redirectUrl}></Navigate> : null}

      <AuthLayout bottomLinks={<BottomLink />}>
        <h4 className="h4 mb-0 mt-3">{t("Welcome back!")}</h4>
        <p className="text-muted mt-1 mb-4">{loginText}</p>

        {error && (
          <Alert variant="danger" className="my-2">
            {error}
          </Alert>
        )}

        <VerticalForm<UserData>
          onSubmit={onSubmit}
          resolver={schemaResolver}
          defaultValues={{}}
          formClass="authentication-form"
        >
          <FormInput
            type="email"
            name="email"
            label={t("Admin Email Address")}
            startIcon={<FeatherIcons icon={"mail"} className="icon-dual" />}
            placeholder={t("hello@domain.com")}
            containerClass={"mb-3"}
          />
          <FormInput
            type="password"
            name="password"
            label={t("Admin Password")}
            startIcon={<FeatherIcons icon={"lock"} className="icon-dual" />}
            action={
              <Link
                to="/auth/forget-password"
                className="float-end text-muted text-unline-dashed ms-1"
              >
                {t("Forgot your password?")}
              </Link>
            }
            placeholder={t("Enter your Password")}
            containerClass={"mb-3"}
          ></FormInput>
          <FormInput
            type="email"
            name="memberEmail"
            label={t("Member Email Address")}
            startIcon={<FeatherIcons icon={"mail"} className="icon-dual" />}
            placeholder={t("hello@domain.com")}
            containerClass={"mb-3"}
          />
          <FormInput
            type="checkbox"
            name="checkbox"
            label={t("Remember me")}
            containerClass={"mb-3"}
            defaultChecked
          />

          <div className="mb-3 text-center d-grid">
            <Button type="submit" disabled={loading}>
              {loading ? t("Logging In...") : t("Log In")}
            </Button>
          </div>
        </VerticalForm>
      </AuthLayout>
    </>
  );
}
