import { api } from "@/apps/common/api-client";
import { PageTitle } from "@/apps/common/components";
import MarkdownModal from "@/apps/common/components/MarkdownModal/MarkdownModal";
import { CONTENT_TEMPLATE, MEMBER_SERVICE_STATUS } from "@/apps/common/constants";
import { formatDate, getUserTimezone } from "@/apps/common/helpers/date";
import { getImageUrl } from "@/apps/common/helpers/images";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import { InsuranceProviderType, ServiceProviderType } from "@/apps/common/types/appointment-types";
import { EventClickArg } from "@fullcalendar/core/index.js";
import { useRequest } from "ahooks";
import startCase from "lodash/startCase";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader
} from "react-bootstrap";
import { MemberServiceContext } from "../../../common/components/contexts/MemberServiceContext";
//import useSWR from 'swr'

import {
  TimeSlotsType,
  useCommonStore
} from "../../../common/store/useCommonStore";
import ConfirmAppointmentModal from "./ConfirmAppointmentModal";
import Calendar from "./Calendar";
import { EventData } from "./interfaces";
import { FormInput, VerticalForm } from "@/apps/common/components/";
import { FaArrowsUpDown } from "react-icons/fa6";
import Form from 'react-bootstrap/Form';
import { PAYMENT_TYPE } from "@/apps/common/constants";
import { LegacyUserType } from "@/apps/common/api-client/api-client.types";

function ProviderProfile({
  provider,
  onSelect,
  active = false,
  singleProviderMode = false
}: {
  provider: ServiceProviderType & { nextAvailableSlot?: { startDate: string } };
  onSelect: (id: number) => void;
  active: boolean;
  singleProviderMode: boolean;
}) {
  const providerName = `${provider.user?.firstName} ${provider.user?.lastName}`;

  return (
    <div className="d-flex flex-column">
      <div className="d-flex gap-3">
        <img
          className="border rounded object-fit-cover"
          style={{ width: "180px", height: "180px" }}
          src={getImageUrl(provider.user?.profilePicture?.url)}
          alt={providerName}
        />
        <div className="d-flex flex-column gap-2">
          <h1 className="my-0">{providerName}</h1>
          <h2 className="my-0">{provider.qualification}</h2>
          {active ? null : (
            <div className="my-0 d-flex flex-row gap-2 align-items-center">
              <Button
                className="px-4 py-2 rounded-pill fs-4"
                size="lg"
                onClick={() => onSelect(provider.id)}
              >
                Book Session with {provider.user?.firstName}
              </Button>
              <p className="my-0">
                Next Available:{" "}
                <strong>
                  {provider.nextAvailableSlot
                    ? formatDate(provider.nextAvailableSlot.startDate)
                    : "Not available"}
                </strong>
              </p>
            </div>
          )}
          {provider.tags ? (
            <div className="d-flex py-2 flex-wrap">
              {provider.tags.map((tag) => (
                <Badge
                  key={tag}
                  bg="light"
                  pill
                  className="px-3 py-1 mb-3 me-4 rounded border border-primary text-primary fs-5 fw-normal"
                >
                  {tag}
                </Badge>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <p className="mt-2">{provider.about}</p>
      {provider.fullProfileLink && (!active || singleProviderMode) ? (
        <Button
          target="_blank"
          href={provider.fullProfileLink}
          variant="outline-primary rounded-pill"
          className="align-self-end"
        >
          View Full Profile
        </Button>
      ) : null}
    </div>
  );
}

// const fetchInsuranceProviders = async (url:any, state:number ) => {
//   const { data } = await api.insuranceProvider.find(state)
//   return data;
// };

export default function CalendarApp() {
  const { memberService, refreshMemberService } =
    useContext(MemberServiceContext);

  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [pymntType, setPaymentType] = useState<string>(PAYMENT_TYPE.SELF);
  const [insuranceProviders, setInsuranceProviders] = useState<InsuranceProviderType[]>([]);
  const [selectedInsuranceProvider, setSelectedInsuranceProvider] = useState<number>();
  const [showPaymentTypeModal, setShowPaymentTypeModal] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [showTimeSlotRequest, setShowTimeSlotRequest] =
    useState<boolean>(false);
  const [hasConsent, setHasConsent] = useState<boolean>(false);
  const [showConsentModal, setShowConsentModal] = useState<boolean>(false);
  const [contractLoading, setContractLoading] = useState<boolean>(false);
  const [eventData, setEventData] = useState<EventData>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [slotType, setSlotType] = useState<number | undefined>(undefined)

  const onCloseModal = () => {
    setShow(false);
    setEventData({});
  };

  const { loading: saving, runAsync: updateUser } = useRequest(
    api.auth.updateUser.bind(api.auth),
    {
      onSuccess: (result, params) => {
        try {
          console.debug("user update succeeded - " + JSON.stringify(result));
          useAuthStore.getState().fetchUser();
        } catch (error) {
          console.debug("user update failed - " + JSON.stringify(error));
          console.error(error);
        }
      },
      manual: true
    }
  );

  const onOpenModal = () => setShow(true);

  const { fetchContentTemplate, contentTemplate, addContract } =
    useCommonStore();

  const { user } = useAuthStore();

  const timeZone = getUserTimezone(user);
  const displayTimezone = getUserTimezone(user, { formatForDisplay: true });

  async function onSelectPaymentType() {

    //console.debug('onSelectPaymentType')

    // If Member Selected OKC update MemberService Payment Type to OKC 
    if ((pymntType == PAYMENT_TYPE.OKLAHOMA_COUNTY) || (pymntType == PAYMENT_TYPE.CONNECTS_BENEFIT)) {
      //console.debug('Changing Payment Type to OKC')
      const data = {
        paymentType: PAYMENT_TYPE.CONNECTS_BENEFIT
      };
      await api.memberServices.updatePaymentType(memberService.id, data).then(
        (res) => {
          console.debug('api.memberServices.updatePaymentType response ' + JSON.stringify(res))
          findServiceProvider()
          console.debug('Found Service Providers - ' + serviceProviders)
          setShowPaymentTypeModal(false)
        }
      )

    }
    if ((pymntType == PAYMENT_TYPE.AITHER)) {
      //console.debug('Changing Payment Type to Aither')
      const data = {
        paymentType: PAYMENT_TYPE.AITHER
      };
      await api.memberServices.updatePaymentType(memberService.id, data).then(
        (res) => {
          console.debug('api.memberServices.updatePaymentType response ' + JSON.stringify(res))
          findServiceProvider()
          console.debug('Found Service Providers - ' + serviceProviders)
          setShowPaymentTypeModal(false)
        }
      )

    }
    if ((pymntType == PAYMENT_TYPE.FIVEGBENEFITS)) {
      //console.debug('Changing Payment Type to fivegbenefits')
      const data = {
        paymentType: PAYMENT_TYPE.FIVEGBENEFITS
      };
      await api.memberServices.updatePaymentType(memberService.id, data).then(
        (res) => {
          console.debug('api.memberServices.updatePaymentType response ' + JSON.stringify(res))
          findServiceProvider()
          console.debug('Found Service Providers - ' + serviceProviders)
          setShowPaymentTypeModal(false)
        }
      )

    }
    if ((pymntType == PAYMENT_TYPE.INSURANCE)) {

      console.debug('Selecting PaymentType as HealthInsurance')
      const data = {
        paymentType: PAYMENT_TYPE.INSURANCE
      };

      await api.memberServices.updatePaymentType(memberService.id, data).then(
        (res) => {
          console.debug('api.memberServices.updatePaymentType response ' + JSON.stringify(res))

          const userData: Partial<LegacyUserType> = {

            insurance_provider: selectedInsuranceProvider
          };

          updateUser(user!.id, userData).then(
            (res) => {

              findServiceProvider()
              console.debug('Finding Service Providers for Insurance Type ' + selectedInsuranceProvider)
              console.debug('Found Service Providers - ' + JSON.stringify(serviceProviders))
              setShowPaymentTypeModal(false)
            }
          )

        }, (error) => { console.error('Error occurred while updating payMentType') }
      )
    }

    if (pymntType == PAYMENT_TYPE.SELF) {
      const data = {
        paymentType: PAYMENT_TYPE.SELF
      };

      await api.memberServices.updatePaymentType(memberService.id, data).then(
        (res) => {
          console.debug('api.memberServices.updatePaymentType response ' + JSON.stringify(res))

          // const userData: Partial<UserType> = {

          //     insurance_provider:null
          // };
          // updateUser(user!.id, userData);

          findServiceProvider()
          setShowPaymentTypeModal(false)

        }, (error) => { console.error('Error occurred while updating payMentType') }
      )

    }

    setSelectedProvider(undefined)
    setShowCalendar(true)
  }

  const onEventClick = (arg: EventClickArg) => {
    //console.debug(JSON.stringify(timeSlots));
    const filter: TimeSlotsType[] = timeSlots!.filter(
      ({ id }: { id: string }) => id === arg.event.id
    );
    const event = {
      id: String(arg.event.id),
      title: arg.event.title,
      className: arg.event.classNames[0],
      time: `${formatDate(filter[0]?.start, "hh:mm a")} - ${formatDate(
        filter[0]?.end,
        "hh:mm a"
      )}`,
      timezone: timeZone,
      date: formatDate(filter[0]?.start, "LLLL dd, yyyy (cccc)"),
      serviceProvider: startCase(memberService!.serviceProviderType),
      apptType: filter[0].type
    };
    setEventData(event);

    if (hasConsent) {
      onOpenModal();
    } else {
      console.debug('Show Consent')
      setShowConsentModal(true);
    }
  };

  const onConfirmAppointment = async (id: string) => {
    console.debug('onConfirmAppointment')
    try {
      setLoading(true);
      await api.appointments.create({
        timeSlot: id,
        memberService: memberService.id
      });
      console.debug('Appointment Created ')
      await refreshMemberService();
      //setLoading (false)
      //setShow(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const contentTemplateDetails = () => {
    //console.debug  ('contentTemplateDetails')
    if (!contentTemplate.length) {
      //console.debug  ('Empty Content Template')
      return;
    }
    const { attributes } = contentTemplate[0];
    //console.debug ('Content Template Attributes - ' + JSON.stringify(attributes))
    const slug = attributes.slug.replaceAll("-", " ");
    return {
      slug: `${slug.slice(0, 1).toUpperCase()}${slug.slice(1)}`,
      content: attributes.content
    };
  };

  const getContract = useCallback(async () => {
    try {

      let content_template = CONTENT_TEMPLATE.CONSENT_FORM
      if ((memberService?.serviceType?.name === "Health and Wellness Peer Support") || (memberService?.serviceType?.name === "Health and Wellness Coaching")) {
        content_template = 'coaching-consent'
      }
      const { data } = await api.contracts.fetchContracts(
        content_template
      );
      //console.debug (`getContract ${content_template}`)
      if (!data.data.length) {

        fetchContentTemplate(content_template);
        //setHasConsent(false);
        //console.debug (`Fetching Consent Template - ${contentTemplate}`)
      } else {
        setHasConsent(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [fetchContentTemplate]);

  const acceptContract = async () => {
    try {

      //console.debug ('acceptContract')
      let content_template = CONTENT_TEMPLATE.CONSENT_FORM
      if ((memberService?.serviceType?.name === "Health and Wellness Peer Support") || (memberService?.serviceType?.name === "Health and Wellness Coaching")) {
        content_template = 'coaching-consent'
      }
      setContractLoading(true);
      const data = {
        content: contentTemplateDetails()?.content,
        signedBy: user?.id.toString() || "",
        fullName: `${user?.firstName} ${user?.lastName}`,
        slug: content_template
      };
      await addContract({ data });
      await getContract();
      setContractLoading(false);
      setShowConsentModal(false);

      onOpenModal();
    } catch (err) {
      console.log(err);
      setContractLoading(false);
    }
  };

  const { data: serviceProviders = [], run: findServiceProvider } = useRequest(() =>
    api.serviceProviders.findAvailable(memberService!.id)
  );

  const [selectedProvider, setSelectedProvider] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (serviceProviders?.length === 1) {
      setSelectedProvider(serviceProviders[0].id);
    }
  }, [serviceProviders]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  }, [selectedProvider]);

  const { data: timeSlots, loading: slotsLoading } = useRequest(
    () => api.appointments.fetchMemberAvailableTimeSlots(selectedProvider!, slotType),
    {
      ready: !!selectedProvider,
      refreshDeps: [selectedProvider]
    }
  );

  useEffect(() => {

    if (memberService) {
      console.debug('SERVICE TYPE' + memberService!.serviceType!.name)
      if (['Health and Wellness Coaching',
        'Health and Wellness Peer Support'].includes(memberService!.serviceType!.name)) {
        console.debug('Selecting all slots)')
        setSlotType(undefined)
      }
      else {
        console.debug('Setting 60 min slot')
        setSlotType(60)
      }

      if (memberService.status == MEMBER_SERVICE_STATUS.APPOINTMENT_RESCHEDULE)
        setShowPaymentTypeModal(false)
      setSelectedProvider(undefined)
      setShowCalendar(true)

    }

  }, [memberService]

  )

  useEffect(() => {
    getContract();
  }, [getContract]);

  const getInsuranceProviders = () => {

    if (user)
      api.insuranceProvider.find(user.state.id).then(
        (res) => {
          console.debug('getInsuranceProviders - ' + JSON.stringify(res))
          setInsuranceProviders(res)
        }
      )

  }

  useEffect(() => {
    if (user)
      getInsuranceProviders();
  }, [user]);

  const filteredProviders = useMemo(() => {
    if (!serviceProviders) {
      return [];
    }

    if (!selectedProvider) {
      return serviceProviders;
    }

    return serviceProviders.filter(
      (provider) => provider.id === selectedProvider
    );
  }, [serviceProviders, selectedProvider]);

  function onChangeVal(value: any) {

    console.debug('Insurance Provider val ' + value)

    const paymentType = parseInt(value)

    if (isNaN(paymentType)) {
      setPaymentType(value)
    }
    else {
      setPaymentType(PAYMENT_TYPE.INSURANCE)
      setSelectedInsuranceProvider(paymentType)
      // Save the Insurance Provider for the user
    }

  }

  const handleSubmit = (formData: any) => {
    if (formData?.timeSlots) {
      //console.debug ('TimeSlot Request Submisson - ' + formData.timeSlots)
      const data = {
        data: {
          user: user?.id,
          timeslot: formData?.timeSlots
        }
      };
      api.common.requestTimeSlots(data).then(
        (response) => {
          console.debug("Successfully submitted timeSlot request");
          setShowTimeSlotRequest(false);
          return response;
        },
        (response) => {
          console.error(
            "Failed to submit timeSlot request - " + JSON.stringify(response)
          );
        }
      );
    }
  };

  const timeSlotRequestForm = (
    <VerticalForm onSubmit={handleSubmit} formClass="authentication-form row">
      <FormInput
        type="textarea"
        name="timeSlots"
        label="Please let us know what day and times (with timezone) would work for you ?"
        //startIcon={<FeatherIcons icon={"activity"} className="icon-dual" />}
        containerClass={"mb-2 col-sm-12 col-md-12 col-lg-12"}
      />

      <div className="mb-2 text-center d-grid col col-md-6 col-lg-6 col-md-offset-3">
        <Button
          variant="secondary"
          type="reset"
          onClick={() => {
            setShowTimeSlotRequest(false);
          }}
        >
          Cancel
        </Button>
      </div>
      <div className="mb-2 text-center d-grid col col-md-6 col-lg-6 col-md-offset-3">
        <Button variant="primary" type="submit">
          Submit Request
        </Button>
      </div>
    </VerticalForm>
  );

  //console.debug ('Member Service Payment Type - ' + memberService.paymentType)
  //console.debug ('Member Service Type - ' +  memberService?.serviceType?.name)

  return (
    <>
      <PageTitle title={"Schedule an appointment"} />

      {![PAYMENT_TYPE.CLINIC,
      PAYMENT_TYPE.PROVIDER,
      PAYMENT_TYPE.CONNECTS_BENEFIT,
      PAYMENT_TYPE.AITHER,
      PAYMENT_TYPE.FIVEGBENEFITS,
      PAYMENT_TYPE.WORKERS_COMP].includes(memberService.paymentType) && ((memberService?.serviceType?.name !==
        "Health and Wellness Coaching"
        ) && (memberService?.serviceType?.name !== "Health and Wellness Peer Support")) ? (
        <Modal show={showPaymentTypeModal} >
          <Modal.Header>
            <Modal.Title as="h4">Select Payment Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Select aria-label="Default select example" onChange={(event) => {
              onChangeVal(event.target.value);
            }}>
              <option value={PAYMENT_TYPE.SELF}>I will pay using a credit card</option>
              <option value={PAYMENT_TYPE.CONNECTS_BENEFIT}>Connect Benefit (employees only)</option>
              <option value={PAYMENT_TYPE.AITHER}>Aither Health</option>
              <option value={PAYMENT_TYPE.FIVEGBENEFITS}>5G Benefits</option>
              {insuranceProviders &&
                insuranceProviders.map((v: InsuranceProviderType, i: number) => (
                  <option key={i} value={v.id} >
                    {v?.description}
                  </option>
                ))}
            </Form.Select>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onSelectPaymentType}>
              Select
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null
      }
      {showCalendar ? (
        <Card >
          <Card.Body>
            <Row>
              <Col>
                {filteredProviders.map((provider) => (
                  <>
                    <ProviderProfile
                      key={provider.id}
                      active={provider.id === selectedProvider}
                      provider={provider}
                      singleProviderMode={serviceProviders.length === 1}
                      onSelect={(provider) => setSelectedProvider(provider)}
                    />
                    <div
                      className="bg-primary my-3"
                      style={{ height: 2, width: "100%" }}
                    />
                  </>
                ))}
                {selectedProvider ? (
                  <Button
                    variant="secondary"
                    className="mt-2"
                    onClick={() => setSelectedProvider(undefined)}
                  >
                    Select a different provider
                  </Button>
                ) : null}
                <Button
                  variant="link"
                  onClick={() => setShowTimeSlotRequest(true)}
                >
                  Don't see a time that works for you? Click here to request a
                  time
                </Button>
              </Col>

              {selectedProvider ? (
                <Col>
                  <Alert variant="secondary">
                    <Row>
                      <Col>
                        Your Timezone: <strong>{displayTimezone}</strong>
                      </Col>
                      <Col className="text-right">
                        Scroll to see more slots <FaArrowsUpDown />
                      </Col>
                    </Row>
                  </Alert>

                  {timeSlots !== undefined ? (
                    <Calendar
                      onEventClick={onEventClick}
                      events={slotsLoading ? [] : timeSlots}
                      timeZone={timeZone}
                    />
                  ) : (
                    <Spinner />
                  )}
                </Col>
              ) : null}
            </Row>
          </Card.Body>
        </Card>) : null}

      <Modal
        show={showTimeSlotRequest}
        centered
        onHide={() => setShowTimeSlotRequest(false)}
      >
        <ModalHeader closeButton={true}>
          <h4 className="modal-title">Request for appointment</h4>
        </ModalHeader>
        <ModalBody>{timeSlotRequestForm}</ModalBody>
      </Modal>

      <MarkdownModal
        title={"We need your consent"}
        loading={contractLoading}
        content={contentTemplateDetails()?.content}
        acceptText={`By indicating my consent, I am signing this agreement electronically. I agree my electronic signature is the legal equivalent of my manual signature on this Agreement. If I am signing on behalf of another individual (including a minor), I attest that I have the authority to do so. Please note the following must have the consent of a parent or guardian: Patients in Alabama/Nebraska under 19 years old: patients in South Carolina under 16 years old: and patients under 18 years old in all other states.`}
        show={showConsentModal}
        onAccept={acceptContract}
        type={memberService?.serviceType?.name === "Health and Wellness Coaching" ? "coaching-consent" : "consent"}
        onHide={() => setShowConsentModal(false)}
      />

      {show ? (
        <ConfirmAppointmentModal
          loading={loading}
          isOpen={show}
          onClose={onCloseModal}
          eventData={eventData}
          onConfirmAppointment={onConfirmAppointment}
        />
      ) : null}
    </>
  );
}
