//import ReferExistingField from "@/apps/common/pages/NewReferral/ReferExistingField";
import { useMemo } from "react";
import { api } from "../api-client";
import AsyncSelectWidget from "../components/form-widgets/AsyncSelectWidget";
import { defaults } from "lodash-es";

type SchemaProps = {
  mode: "create" | "edit" | "preview" | "admin";
  userStateId: number
}

function iif(condition: boolean, then: any, otherwise: any = undefined) {
  return condition ? then : otherwise;
}

//const portalType = getPortalType();

const getHealthInsuranceSchema = ({ mode }: SchemaProps) => ({
  type: "object",
  required: [
    "firstLegalName",
    "lastLegalName",
    "phoneNumber",
    "groupNumber",
    "memberId",
    "insuranceProvider"
  ],
  properties: {
    firstLegalName: {
      type: "string",
      title: "First Name (Legal)",
      description: "Please enter your legal First Name",
      minLength:1,
      pattern: "^[a-zA-Z]"
    },
    lastLegalName: {
      type: "string",
      title: "Last Name (Legal)",
      description: "Please enter your legal Last Name",
      minLength:1,
      pattern: "^[a-zA-Z]"
    },
    phoneNumber: {
      type: "string",
      title: "Phone Number",
      description: "Please enter your Phone Number",
      minLength:9,
      //pattern: "^(\\([0-9]{3}\\))?[0-9]{3}-[0-9]{4}$"
    },
    insuranceProvider: {
      type: "number",
      title: "Insurance Provider",
      description: "Please select your Insurance Provider"
    },
    groupNumber: {
      type: "string",
      title: "Insurance Group Number",
      description: "Please enter your Insurance Group Number",
      minLength:3
    },
    memberId: {
      type: "string",
      title: "Insurance Member Id",
      description: "Please enter your Insurance Member Id",
      minLength:3
    },
    defaultCopay: {
      type: "number",
      title: "Default Copay Amount",
      description: "Please enter the default copay amount",
    },
  }
});

const getHealthInsuranceUISchema = ({
  mode,
  userStateId
}: SchemaProps) => ({

  "ui:submitButtonOptions": {
    norender: mode === "preview",
  },

  "ui:layoutOptions": { gridColumnProps: { xs: 6 } },

  firstLegalName: {
    "ui:options": {
      placeholder: "First Name (legal)"
    },
    "ui:readonly": iif(mode === "preview", true),
  },

  lastLegalName: {
    "ui:options": {
      placeholder: "Last Name (legal)"
    },
    "ui:readonly": iif(mode === "preview", true)
  },

  phoneNumber: {
    "ui:options": {
      placeholder: "Phone Number"
    },
    "ui:readonly": iif(mode === "preview", true)
  },
  // insuranceProvider:{
  //   "ui:options": {
  //     placeholder: "Insurance Provider"
  //   },
  //   "ui:readonly": true
  // },
  insuranceProvider: {
    "ui:widget": AsyncSelectWidget,
    //"ui:readonly": iif(((mode === "edit") || (mode === "preview")), true),
    //"ui:readonly": iif(((mode === "edit") || (mode === "preview")), true),
    "ui:options": {
      placeholder: "Select Insurance Provider"
    },
    requestCacheKey: "insuranceProviders",
    request: () => api.insuranceProvider.findForDropDown(userStateId),
    selectItemOnLoad: iif(((mode === "edit") || (mode === "preview") || (mode === 'admin')), true)
    //selectItemOnLoad: iif(((mode === "create") || (mode === "preview")), true)
  },

  groupNumber: {
    "ui:options": {
      placeholder: "Insurance Group Number"
    },
    "ui:readonly": iif(mode === "preview", true),
  },

  memberId: {
    "ui:readonly": iif(mode === "preview", true),
    "ui:options": {
      placeholder: "Insurance Member Id"
    },
  },

  defaultCopay: {
    "ui:readonly": iif(mode === "preview", true),
    "ui:widget": iif(mode !== "admin", "hidden"),
  },

  
});

function customValidate(formData: any, errors: any) {

  console.debug ('customValidate - ' + JSON.stringify(errors))
  //   if (!isValidDateFormat(formData?.dateOfBirth, DATE_FORMATS.DATE_ONLY)) {
  //     errors.dateOfBirth.addError("Please enter date in MM/DD/YYYY");
  //   }
  // if ( !isValidUSZip(formData?.zip) ){
  //   errors.zip.addError ("Pleaser enter a valid 5 digit US Zip Code")
  // }

  //console.debug ('errors ' + JSON.stringify(errors))
  return errors;
}

function transformErrors(errors: any[]) {
  
  return errors.map((error) => {
    console.debug ('transformErrors - ' + JSON.stringify(error))
    if ((error.name === "pattern") && ((error.property === ".lastLegalName") || (error.property === ".firstLegalName"))){
      console.debug ('Custom error message')
      error.message = "Please provide a valid legal name";
    }
    if (error.name === "required") {
      error.message = "Please fill in this field";
    } else if (error.name === "format") {
      error.message = "Please enter a valid value";
    }
    return error;
  });
}

export function useHealthInsuranceFormConfig(props: Partial<SchemaProps>) {
  const withDefaultProps = defaults(props, {
    mode: "create",
    userStateId: 0
  });


  const schema = useMemo(
    () => getHealthInsuranceSchema(withDefaultProps),
    [withDefaultProps]
  );

  const uiSchema = useMemo(() => {
    return getHealthInsuranceUISchema(withDefaultProps);
  }, [withDefaultProps]);

  return {
    schema,
    uiSchema,
    customValidate,
    transformErrors
  };
}