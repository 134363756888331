import { api } from "@/apps/common/api-client";
//import { FormInput, InputMasks, VerticalForm } from "@/apps/common/components/";
import {
  ServiceProviderType,
  ServiceProvidertypeType,
  ServiceType,
  StateType,
  LanguageType
} from "@/apps/common/types/appointment-types";
//import { LegacyUserType } from "@/apps/common/api-client/api-client.types";
//import EditProfileModal from "@/apps/clinic/pages/Profile/EditProfileModal";
import ProfileDetails from "@/apps/common/components/ProfileDetails";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
//import { yupResolver } from "@hookform/resolvers/yup";
//import FeatherIcons from "feather-icons-react";
import { useEffect, useMemo, useState } from "react";
import { Alert, Spinner, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
//import * as yup from "yup";
import PaymentMethods from "@/apps/common/components/PaymentMethods";
import ProviderProfileForm from "@/apps/common/components/ProviderProfile"
//import { object, AnySchema, string } from 'yup';
import { useRequest } from "ahooks";


interface ServiceProviderData {
  id?: number | undefined;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  qualification: string;
  about?: string;
  states?: StateType[] | undefined;
  stripeId?: string | undefined;
  state: number | null,
  npi: string,
  serviceTypes?: ServiceType[],
  service_provider_types: ServiceProvidertypeType[],
  languages: LanguageType[]
}

const Profile = () => {

  //const [showConfirmUpdateModal, setshowConfirmUpdateModal] = useState(false);
  //const [savingProfile, setSavingProfile] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [refreshView, setRefreshView] = useState<boolean>(false);
  const { t } = useTranslation();
  const defaultValues: ServiceProviderData = {
    id: -1,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    qualification: "",
    about: "",
    states: [],
    state: null,
    npi: '',
    serviceTypes: [],
    service_provider_types: [],
    languages: []
  };
  const [serviceProviderData, setServiceProviderData] =
    useState<ServiceProviderData>(defaultValues);
  const [selectedServiceProvider, setSelectedServiceProvider] = useState<ServiceProviderType | null>(null)
  let serviceProviderProfile: Partial<ServiceProviderType>;

  //const [selected_states, setSelectedStates] = useState<StateType[]>([]);

  const refresh = () => {
    setRefreshView(true);
  };

  const args = {
    populate: ["user", "user.state", "user.profilePicture", "states", "serviceTypes", "service_provider_types", "languages", "paymentProfile"],
    filters: {
    }
  }
  const { data: serviceProvider, loading, run: getServiceProviderProfileData } = useRequest(() =>
    api.serviceProviders.findMany(args)
      .then((providers: any) => {
        //console.debug (`serviceProviderData ${JSON.stringify(providers[0])}`)
        //setServiceProviderData(providers[0])
        return providers[0]
      },
        (err) => {
          console.debug('Error fetching serviceProviderData')
        }
      )
  );

  useMemo(() => {

    const a = serviceProvider;
    //console.debug('UseMemo - ' + JSON.stringify(a))
    if (a?.id) {
      //console.debug('useMemo serviceProviders - ' + JSON.stringify(serviceProvider))
      setSelectedServiceProvider(serviceProvider)
      const _data: ServiceProviderData = {
        id: a.id,
        address: a.address,
        qualification: a.qualification!,
        about: a.about!,
        states: a.states,
        firstName: a.user!.firstName,
        lastName: a.user!.lastName,
        email: a.user!.email,
        phoneNumber: a.user!.phoneNumber,
        state: a.user?.state?.id,
        npi: a.npi,
        serviceTypes: a?.serviceTypes,
        service_provider_types: a?.service_provider_types,
        languages: a?.languages
        //stripeId: a.attributes.paymentProfile.attributes.stripeId ? a.attributes.paymentProfile.attributes.stripeId :null
      };

      if (a.states) {
        _data.states = a.states;
      }
      //getServiceProviderProfileData()
      setServiceProviderData(_data);
      //setSelectedServiceProvider(serviceProvider)
      //console.debug('_data - ' + JSON.stringify(_data))
    }
  }, []);

  useEffect(() => {

    if (!serviceProvider) {
      getServiceProviderProfileData()
      console.debug('Provider My Account - useEffect[] - ' + JSON.stringify(serviceProvider))
    }
    if (serviceProvider?.id)
      setSelectedServiceProvider(serviceProvider)

  }, [serviceProvider]);

  useEffect(() => {

    getServiceProviderProfileData()

    if ((loading === false) && (serviceProvider?.id)) {
      console.debug('Provider My Account - useEffect[] - ' + JSON.stringify(serviceProvider))
      setSelectedServiceProvider(serviceProvider)
    }

  }, []);

  useEffect(() => {
    //console.debug('useEffect - ' + loading)
    if ((loading === true) && (!selectedServiceProvider)) setDataLoaded(false);
    else setDataLoaded(true);
  }, [loading, selectedServiceProvider]);

  const displayServiceProviderData = () => {

    if (selectedServiceProvider?.type === "mental-health-coach")
      return true

    if ((selectedServiceProvider?.id) 
      // &&
      // (selectedServiceProvider?.npi) &&
      // (selectedServiceProvider?.service_provider_types) &&
      // (selectedServiceProvider?.serviceTypes) &&
      // (selectedServiceProvider?.states)
    )
      return true
    else
      return false

  }

  return (
    <>
      <ProfileDetails
        /*cardDetails={paymentMethod?.[0]?.attributes}*/ refreshParent={refresh}
      >
        <>

          {dataLoaded == false ? (
            <Card.Body>
              <div className="text-center p-4 mt-4">
                <Spinner
                  style={{ width: 100, height: 100 }}
                  animation="border"
                  variant="info"
                />
              </div>
            </Card.Body>
          ) : null}

          {(dataLoaded == true && selectedServiceProvider) && (
            <div className={`${!dataLoaded ? "invisible" : ""}`}>
              {serviceProvider?.status === 'pending' && (serviceProvider?.type !== "mental-health-coach") ? (
                <Alert>
                  Thank you for signing up as a counselor with Skyler Health! We are currently reviewing your application. We will be contacting you soon with additional information to on-board.
                </Alert>
              ) : (serviceProvider?.status === 'pending' && serviceProvider?.type === "mental-health-coach" ?
                <Alert>Thank you for signing up with Skyler Health! We are currently reviewing your application. We will be contacting you soon with additional information to on-board.</Alert>
                : null
              )
              }
              {displayServiceProviderData() ? (
                <ProviderProfileForm
                  mode={"edit"}
                  serviceProvider={selectedServiceProvider}
                  updateData={setSelectedServiceProvider}
                  providerProfileFormData={() => { }} />

              ) : null
              }
              {/* {profileForm} */}
              {/*{serviceProviderDetails}*/}
              {/* {showConfirmUpdateModal ? (
                <EditProfileModal
                  show={true}
                  onClick={updateProfile}
                  //onClick={() => { }}
                  loading={savingProfile}
                  onHide={onCloseModal}
                />
              ) : null} */}
            </div>
          )}
        </>
      </ProfileDetails >
      {selectedServiceProvider?.status === "approved" ? (
        <PaymentMethods userType={"provider"} userId={null} />
      ) : null
      }
    </>
  );
};

export default Profile;
