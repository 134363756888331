import { generateForm } from "@modules/react-jsonschema-form-bootstrap";
//import { IChangeEvent } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { HealthInsuranceData } from "../../api-client/modules/common.module";
import { useHealthInsuranceFormConfig } from "../../forms/HealthInsuranceForm";
import { HealthInsuranceType, UserType } from "../../types/appointment-types";
import { api } from "@/apps/common/api-client";
import { LegacyUserType } from "@/apps/common/api-client/api-client.types";
import { useRequest } from "ahooks";
import { useAuthStore } from "@/apps/common/store/useAuthStore";


const Form = generateForm<HealthInsuranceData>();

export default function HealthInsuranceForm({
  mode,
  healthInsurance,
  user = null,
  display
  //onSubmitForm,
  //formSubmitStatus,
}: {
  mode: "create" | "edit" | "preview" | "admin";
  healthInsurance: HealthInsuranceType | undefined;
  user: Partial<UserType> | null | undefined;
  display: (value: boolean) => void;
  //onSubmitForm: (formData: Partial<HealthInsuranceType>) => void
  //formSubmitStatus: boolean | null
}) {

  // Updating User Data
  const { loading: saving, runAsync: updateUser } = useRequest(
    api.auth.updateUser.bind(api.auth),
    {
      onSuccess: (result, params) => {
        try {
          //console.debug("user update succeeded - " + JSON.stringify(result));
          useAuthStore.getState().fetchUser();
        } catch (error) {
          //console.debug("user update failed - " + JSON.stringify(error));
          console.error(error);
        }
      },
      manual: true
    }
  );

  function onSubmitHealthInsuranceForm(formData: any) {

    //console.debug('submitting HealthInsurance form - ' + JSON.stringify(formData))
    //setHealthInsuranceData(formData);

    const _healthInsurance = {
      firstLegalName: formData.firstLegalName,
      lastLegalName: formData.lastLegalName ? formData.lastLegalName : '',
      groupNumber: formData.groupNumber,
      memberId: formData.memberId,
      phoneNumber: formData.phoneNumber,
      defaultCopay: formData?.defaultCopay
    }

    try {

      if (formData.insuranceProvider != user?.insurance_provider) {
        //.debug ('Update the insurane Provider for the user')
        //console.debug ('Saving InsuranceProvider information ' + JSON.stringify(formData.insuranceProvider))
        // Update the insurance provider
        const userData: Partial<LegacyUserType> = {

          insurance_provider: formData.insuranceProvider
        };

        if (user?.id)
          updateUser(user.id, userData).then(
          (res) => {
          }
        )
      }

      if (healthInsurance?.id) {
        //console.debug('Updating an existing HealthInsurance')
        const data = {
          ..._healthInsurance
        }

        api.healthInsurance.update(healthInsurance.id, data).then((res) => {

          setSubmitMessage("Health Insurance data was updated successfully !")
          showAlert()
          display (false)
          //setFormSubmitStatus(true)
          //setHealthInsuranceData(res)
        },
          (err) => {
            setSubmitMessage("An error occurred while updating the Health Insurance data. Please try again.")
            showAlert()            
            //setFormSubmitStatus(false)
          });
      }
      else {

        const a = {
          ..._healthInsurance,
          user: user?.id
        }
        api.healthInsurance.create(a).then((res) => {

          //setShowTeleHealthLocation(false)
          //setSubmitMessage("Telehealth Location changes were saved successfully !")
          //display(false)
          //setFormSubmitStatus(true)

          setSubmitMessage("Health Insurance data was updated successfully !")
          showAlert()
          display(false)
        },
          (err) => {
            //setFormSubmitStatus(false)
            setSubmitMessage("An error occurred while updating the Health Insurance data. Please try again.")
            showAlert()
          }
        )
      }

    } catch (err) {
      console.log(err);
    }

  }

  //console.debug('HealthInsurance Form')
  //console.debug('User - ' + JSON.stringify(user))
  //console.debug('healthInsurance - ' + JSON.stringify(healthInsurance))

  const [submitMessage, setSubmitMessage] = useState<string>('');
  const [displaySubmitMessage, setDisplaySubmitMessage] = useState<boolean>(false);
  //const [loading, setLoading] = useState(false);
  //const [healthInsuranceData, setHealthInsuranceData] = useState<any>();
  const [userStateId, setUserStateId] = useState<number | undefined>(user?.state?.id)

  const setValues = () => {

    let data: HealthInsuranceData;

    if (!healthInsurance) {
      //console.debug(' No HealthInsurance Information associated with this member')
      data = {
        firstLegalName: user?.firstName ? user?.firstName : '',
        lastLegalName: user?.lastName ? user?.lastName : '',
        phoneNumber: user?.phoneNumber ? user?.phoneNumber : '',
        groupNumber: '',
        memberId: '',
      }
      if (user?.insurance_provider) {
        data.insuranceProvider = user.insurance_provider?.id;
        mode = "edit"
      }
      else {
        mode = "create"
      }

    }
    else {
      data = {
        ...healthInsurance,
        insuranceProvider: user?.insurance_provider ? user.insurance_provider.id : -1
      }
    }

    //console.debug(' setting Health Insurance data ' + JSON.stringify(data))
    return data

  }

  const [defaultValues, setDefaultValues] =
    useState<Partial<HealthInsuranceData>>(setValues());

  const showAlert = () => {
    //console.debug (submitMessage)
    setDisplaySubmitMessage(false);
    setDisplaySubmitMessage(true)
    setTimeout(() => {
      setSubmitMessage('')
      setDisplaySubmitMessage(false);
    }, 3000);

  }

  // useEffect(() => {

  //   if (formSubmitStatus === true) {
  //     console.debug ('SUCCESS')
  //     setSubmitMessage("HealthInsurance changes saved successfully!")
  //   }
  //   else if (formSubmitStatus === false) {
  //     console.debug ('ERROR')
  //     setSubmitMessage("An Error occurred while saving HealthInsurance changes, Please try again.")
  //   }
  //   else if (!formSubmitStatus) {
  //     setSubmitMessage('')
  //   }

  //   //console.debug (submitMessage)

  //   // if ((submitMessage === "Updating your HealthInsurance information ...") ||
  //   //    (submitMessage === "HealthInsurance changes saved successfully!"))
  //   // {
  //   //   showAlert()
  //   // }      

  // }, []);

  // useEffect(() => {

  //   if (formSubmitStatus === true) {
  //     console.debug ('SUCCESS')
  //     setSubmitMessage("HealthInsurance changes saved successfully!")
  //   }
  //   else if (formSubmitStatus === false) {
  //     console.debug ('ERROR')
  //     setSubmitMessage("An Error occurred while saving HealthInsurance changes, Please try again.")
  //   }
  //   else if (!formSubmitStatus) {
  //     setSubmitMessage('')
  //   }

  //   //console.debug (submitMessage)

  //   // if ((submitMessage === "Updating your HealthInsurance information ...") ||
  //   //    (submitMessage === "HealthInsurance changes saved successfully!"))
  //   // {
  //   //   showAlert()
  //   // }      

  // }, [formSubmitStatus]);

  // useEffect(() => {

  //   //console.debug (submitMessage)

  //   if (
  //      (submitMessage === "HealthInsurance changes saved successfully!"))
  //   {
  //     showAlert()
  //   }      

  // }, [submitMessage]);

  useEffect(() => {

    if (healthInsurance)
      setDefaultValues(setValues())

    // if (formSubmitStatus) {

    //   if (formSubmitStatus === true) {
    //     setSubmitMessage("HealthInsurance changes saved successfully !")
    //   }
    //   else {
    //     setSubmitMessage("An Error occurred while saving HealthInsurance changes, Please try again.")
    //   }

    //   //showAlert()
    // }

  }, [healthInsurance]);

  const { schema, uiSchema, customValidate, transformErrors } =
    useHealthInsuranceFormConfig({
      mode,
      userStateId: userStateId
    });

  const _onSubmit = (form: any) => {
      setSubmitMessage("Updating your HealthInsurance information ...")
      showAlert()
      onSubmitHealthInsuranceForm(form?.formData)

      // if (formSubmitStatus === true) {
      //   console.debug ('SUCCESS')
      //   setSubmitMessage("HealthInsurance changes saved successfully!")
      // }
      // else if (formSubmitStatus === false) {
      //   console.debug ('ERROR')
      //   setSubmitMessage("An Error occurred while saving HealthInsurance changes, Please try again.")
      // }

      //showAlert()
  };

  //console.debug('defaultValues for Health Insurance - ' + JSON.stringify(defaultValues))

  return (
    <>
      {defaultValues ?
        (
          <>
            Make sure this is the information of the person seeking care, even if they are a dependent and a minor. Please enter the first name and last name exactly as it appears on the health insurance card. If they are on multiple plans, then only enter their primary insurance that you wish to utilize for the service.
            <br />
            <br />
            <Form
              //formContext={formContext}
              formData={defaultValues as HealthInsuranceData}
              schema={schema as any}
              uiSchema={uiSchema as any}
              validator={validator}
              showErrorList={false}
              customValidate={customValidate}
              noHtml5Validate
              transformErrors={transformErrors}
              onSubmit={_onSubmit}
            ></Form>
            <br />
            <br />
            <Alert variant="secondary" className="mb-0 d-md-block d-sm-none" show={displaySubmitMessage} >
              {submitMessage}
            </Alert>
          </>) : null
      }
    </>
  );
}
