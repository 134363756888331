import { AxiosInstance, AxiosResponse } from "axios";
import { unstrapiMany } from "../../helpers/strapi";
import {
  ConfigurationType,
  CPTCodeType,
  ServiceType,
  ServiceProvidertypeType,
  LanguageType
} from "../../types/appointment-types";
import { Coupon } from "../api-client.types";
import { BaseApiModule } from "./base.module";

export interface ClinicProfileData {
  data: {
    name?: string | undefined;
    location?: string | undefined;
  };
}

export interface TimeSlotData {
  start: string;
  end: string;
  tentative?: boolean;
}

export interface ReferralData {
  referralId?: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  spokenLanguage: string;
  paymentType: string;
  state: number;
  serviceType: number;
  serviceProviderType: string;
  email: string;
  clinic?: number;
  referringDoctor?: string;
  notes?: string;
}

export interface TeleHealthLocationData {
  id?: number;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state?: number;
  zip: string;
  description: string;
  user: number;
}


export interface ProgressReportData {
  id?: number;
  presentingProblem: string;
  subjectiveObservations: string;
  objectiveFindings: string;
  progressAssessment?: string;
  zip: string;
  description: string;
  user: number;
}

export interface HealthInsuranceData {
  id?: number;
  firstLegalName: string;
  lastLegalName: string;
  phoneNumber: string;
  groupNumber: string;
  memberId: string;
  user?: number;
  insuranceProvider?: number
}

export interface Contract {
  data: {
    content: string;
    signedBy: string;
    fullName: string;
    slug: string;
  };
}

export class CommonApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  async fetchServiceTypes() {
    return await this.client
      .get("api/service-types?filters[enabled]=true&sort=order:ASC")
      .then((response) =>
        unstrapiMany<ServiceType>(response.data, "serviceType")
      );
  }

  async fetchAvailableServiceTypes(lang: number | undefined, state: number | undefined) {
    console.debug('fetchAvailableServiceTypes')
    return await this.client
      .get(`api/service-type/availableServices?filters[enabled]=true&sort=order:ASC&lang=${lang}&state=${state}`)
      .then((response) => {
        //console.debug (`raw response - ${JSON.stringify(response.data)}`)
        const a = unstrapiMany<ServiceType>(response.data, "serviceType")
        //console.debug (`unstrapy - ${JSON.stringify(a)}`)
        return a
      }
      );
  }

  async fetchTherapyServiceTypes() {
    return await this.client
      .get("api/service-types?filters[enabled]=true&sort=order:ASC")
      .then((response) => {
        const resp = unstrapiMany<ServiceType>(response.data, "serviceType")
        const returnVal: Partial<ServiceType>[] = []
        resp.forEach(serviceType => {
          console.debug(`${JSON.stringify(serviceType)}`)
          if (!['Health and Wellness Coaching', 'Health and Wellness Peer Support'].includes(serviceType.name)) {
            returnVal.push(serviceType)
          }
        })
        return returnVal;
      });
  }

  async fetchCoachingServiceType() {
    return await this.client
      .get("api/service-types?filters[enabled]=true&sort=order:ASC")
      .then((response) => {
        const resp = unstrapiMany<ServiceType>(response.data, "serviceType")
        const returnVal: Partial<ServiceType>[] = []
        resp.forEach(serviceType => {
          //console.debug(`${JSON.stringify(serviceType)}`)
          if (['Health and Wellness Coaching', 'Health and Wellness Peer Support'].includes(serviceType.name)) {
            returnVal.push(serviceType)
          }
        })
        return returnVal;
      });
  }

  async fetchServiceProviderTypes() {
    return await this.client
      .get("/api/service-provider-types")
      .then((response) => {
        const a = unstrapiMany<ServiceProvidertypeType>(response.data, "service_provider_type")
        // return a.map ((x)=>({
        //   name: x.description,
        //   value: x.id
        // }))
        //console.debug  ('a -' + JSON.stringify(a))
        return a

      }
      );
  }

  async fetchTherapyServiceProviderTypes() {
    return await this.client
      .get("/api/service-provider-types")
      .then((response) => {
        const returnVal: Partial<ServiceProvidertypeType>[] = []
        const a = unstrapiMany<ServiceProvidertypeType>(response.data, "service_provider_type")
        a.forEach(serviceProviderType => {
          console.debug(`serviceProviderType ${JSON.stringify(serviceProviderType)}`)
          if (serviceProviderType.name !== "Health and Wellness Coach") {
            returnVal.push(serviceProviderType)
          }
        }
        )
        return returnVal;
      });
  }

  async fetchServiceProviderTypesforCoaching() {
    return await this.client
      .get("/api/service-provider-types")
      .then((response) => {
        const returnVal: Partial<ServiceProvidertypeType>[] = []
        const a = unstrapiMany<ServiceProvidertypeType>(response.data, "service_provider_type")
        a.forEach(serviceProviderType => {
          //console.debug(`serviceProviderType ${JSON.stringify(serviceProviderType)}`)
          if (serviceProviderType.name === "Health and Wellness Coach") {
            returnVal.push(serviceProviderType)
          }
        }
        )
        return returnVal;
      });
  }

  async fetchLanguages() {
    return await this.client
      .get("/api/languages")
      .then((response) => {
        const a = unstrapiMany<LanguageType>(response.data, "language")
        // return a.map ((x)=>({
        //   name: x.description,
        //   value: x.id
        // }))
        //console.debug  ('a -' + JSON.stringify(a))
        return a

      }
      );
  }

  async fetchCalendar(): Promise<AxiosResponse> {
    //console.debug("fetchCalendar\n");
    return await this.client.get(`/api/calendars`);
  }

  async generateEvaluationReportPDF(id: string): Promise<AxiosResponse> {
    return await this.client.post(`api/evaluation-reports/${id}/generate-pdf`);
  }

  async fetchConfiguration(name: string) {
    return await this.client
      .get(`api/configurations?filters[name]=${name}`)
      .then(
        (response) =>
          unstrapiMany<ConfigurationType>(response.data, "configuration")?.[0]
      );
  }

  async fetchContentTemplate(slug: string) {
    return await this.client.get(`api/content-templates?filters[slug]=${slug}`);
  }
  async addPaymentMethod(): Promise<AxiosResponse> {
    return await this.client.post(`api/payment-methods`);
  }
  async fetchClinic(user: string) {
    return await this.client.get(
      `api/clinic-users?filters[user][id]=${user}&populate=clinic.paymentProfile.paymentMethods,clinic.state,user`
    );
  }
  async addContract(data: Contract): Promise<AxiosResponse> {
    return await this.client.post(`api/contracts`, data);
  }
  async fetchPaymentLogs() {
    return await this.client.get(`api/payment-logs`, {
      params: {
        populate: "paymentMethod,memberService.user,memberService.referral",
        sort: "updatedAt:DESC"
      }
    });
  }

  async updateClinicProfile(
    id: number,
    data: Partial<ClinicProfileData>
  ): Promise<AxiosResponse> {
    //console.log("common.modules updateClinicProfile - " + JSON.stringify(data));
    return await this.client.put(`api/clinics/${id}`, data);
  }

  async fetchCoupons(): Promise<Coupon[]> {
    return await this.client
      .get(`api/coupons`)
      .then((response) => unstrapiMany(response.data, "coupon"));
  }

  async addNewClinicEmail(data: any) {
    return await this.client.post(`api/new-clinics`, data);
  }

  async requestTimeSlots(data: any) {
    //console.debug("/api/time-slot-requests");
    return await this.client.post(`/api/time-slot-requests`, data);
  }

  async uploadImage(data: any, userId: string): Promise<object> {
    const formData = new FormData();
    const a = URL.createObjectURL(data);
    const blob = await fetch(a).then((r) => r.blob());
    formData.append("files", blob, "test.jpeg");
    formData.append("ref", "plugin::users-permissions.user");
    formData.append("refId", userId);
    formData.append("field", "profilePicture");

    //console.debug("Uploading file", { formData });

    return await this.client.post(`api/upload`, formData).then((resp) => {
      //console.debug ('Response - ' + JSON.stringify(resp))
      return resp;
    });
  }

  async fetchCPTCodes() {
    //console.debug("fetching CPT Codes");
    const res = await this.client
      .get("/api/cpt-codes?pagination[limit]=1000", {})
      .then((response) => unstrapiMany<CPTCodeType>(response.data, "cptCode"));
    //console.debug(JSON.stringify(res));
    return res;
  }
}
