import { Route } from "react-router-dom";

// Pages
import Dashboard from "../pages/Dashboard";
import History from "../pages/History/History";
import Billing from "../pages/Billing/Billing";
import ScheduleAppointment from "../pages/ScheduleAppointment/ScheduleAppointment";

// components
import { RouteType } from "@/apps/common/components/RoutesHandler";
import PrivateRoute from "@/apps/common/routes/PrivateRoute";
import MedicalCondtions from "@/apps/member/pages/MedicalConditions/MedicalConditions";
import PaymentStatus from "@/apps/member/pages/PaymentStatus/PaymentStatus";
import Profile from "../pages/Profile/Profile";

// auth
import Confirm from "@/apps/common/pages/auth/Confirm";
import ForgetPassword from "@/apps/common/pages/auth/ForgetPassword";
import Login from "@/apps/common/pages/auth/Login";
import Logout from "@/apps/common/pages/auth/Logout";
import ReferralRegister from "@/apps/common/pages/auth/ReferralRegister";
import Register from "@/apps/member/pages/auth/Register";
import ResetPassword from "@/apps/common/pages/auth/ResetPassword";
import AdminLogin from "../pages/auth/AdminLogin";

// dashboards
const dashboardRoutes: RouteType[] = [
  {
    path: "/dashboard",
    name: "Dashboard",
    element: <Dashboard />,
    route: PrivateRoute
  },
  {
    path: "/payment-method-status",
    name: "PaymentStatus",
    element: <PaymentStatus />,
    route: PrivateRoute
  },
  {
    path: "/my-account",
    name: "MyAccount",
    element: <Profile />,
    route: PrivateRoute
  },
  {
    path: "/history",
    name: "History",
    element: <History />,
    route: PrivateRoute
  },
  {
    path: "/billing",
    name: "Billing",
    element: <Billing />,
    route: PrivateRoute
  },
  {
    path: "/schedule-appointment",
    name: "Schedule Another Session",
    element: <ScheduleAppointment />,
    route: PrivateRoute
  }
];

// auth
const authRoutes: RouteType[] = [
  {
    path: "/auth/admin-login",
    name: "AdminLogin",
    element: <AdminLogin />,
    route: Route
  },
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route
  },
  {
    path: "/auth/referral-register",
    name: "Register",
    element: <ReferralRegister />,
    route: Route
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route
  },
  {
    path: "/auth/reset-password",
    name: "Reset Password",
    element: <ResetPassword />,
    route: Route
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route
  },
  {
    path: "/medical-conditions",
    name: "MedicalConditions",
    element: <MedicalCondtions />,
    route: Route
  }
];

export const defaultRoute = "/dashboard";

export { menuItems } from "./menuItems";

export const authProtectedRoutes = [...dashboardRoutes];
export const publicRoutes = [...authRoutes];
