export const REFERRAL_STATUS = {
  REFERRAL_SENT: "referral-sent",
  JOINED: "joined",
  COMPLETE: "complete",
  ARCHIVED: "archived"
};

export const MEMBER_SERVICE_STATUS = {
  REFERRAL_SENT: "referral-sent",
  REPEAT_REFERRAL_SENT: "repeat-referral-sent",
  SIGNED_UP: "signed-up",
  ONBOARDED: "onboarded",
  SURVEY_STARTED: "survey-started",
  SURVEY_COMPLETED: "survey-completed",
  SURVEY_NOT_REQUIRED: "survey-not-required",
  APPOINTMENT_PAYMENT_PENDING: "appointment-payment-pending",
  APPOINTMENT_PAYMENT_FAILED: "appointment-payment-failed",
  APPOINTMENT_STATUS_PENDING: "appointment-status-pending",
  APPOINTMENT_SCHEDULED: "appointment-scheduled",
  APPOINTMENT_DECLINED: "appointment-declined",
  APPOINTMENT_RESCHEDULE: "appointment-reschedule",
  APPOINTMENT_NO_SHOW: "appointment-no-show",
  APPOINTMENT_COMPLETE: "appointment-complete",
  REPORT_SUBMITTED: "report-submitted",
  REPORT_PAYMENT_PENDING: "report-payment-pending",
  REPORT_GENERATION_STARTED: "report-generation-started",
  REPORT_GENERATION_COMPLETED: "report-generation-completed",
  REPORT_GENERATION_SENT: "report-generation-sent",
  COMPLETE: "complete",
  ARCHIVED: "archived",
  CANCELED: "canceled"
};

export const APPOINTMENT_STATUS = {
  PENDING: "pending",
  AWAITING_PAYMENT: "awaiting-payment",
  PAYMENT_FAILED: "payment-failed",
  CONFIRMED: "confirmed",
  DUE: "due",
  COMPLETED: "completed",
  DECLINED: "declined",
  CANCELLED: "cancelled",
  NO_SHOW: "no-show",
  RESCHEDULED: "rescheduled"
};

export const CONTENT_TEMPLATE = {
  BUSINESS_ASSOCIATE_AGREEMENT: "business-associate-agreement",
  CONSENT_FORM: "consent-form"
};

export const PAYMENT_STATUS = {
  NONE: "none",
  INITIATED: "initiated",
  ATTACHED: "attached",
  FAILED: "failed",
  SUCCESS: "success"
};

export const PAYMENT_TYPE = {
  CLINIC: "clinic",
  PROVIDER: "provider",
  INSURANCE: "health_insurance",
  SELF: "self",
  OKLAHOMA_COUNTY: "okc",
  CONNECTS_BENEFIT: "connects_benefit",
  AITHER: "aither",
  FIVEGBENEFITS: "5gbenefits",
  WORKERS_COMP: "workers_comp"
};

export const PAYMENT_TYPE_DISPLAY = {
  [PAYMENT_TYPE.CLINIC]: "Clinic",
  [PAYMENT_TYPE.SELF]: "Member",
  [PAYMENT_TYPE.PROVIDER]: "Provider",
  [PAYMENT_TYPE.INSURANCE]: "Health Insurance",
  [PAYMENT_TYPE.OKLAHOMA_COUNTY]: "Oklahoma County (by Connect Benefit)",
  [PAYMENT_TYPE.CONNECTS_BENEFIT]: "Connect Benefit",
  [PAYMENT_TYPE.AITHER]: "Aither Health",
  [PAYMENT_TYPE.FIVEGBENEFITS]: "5G Benefits",
  [PAYMENT_TYPE.WORKERS_COMP]: "Workers Comp."
};

export const STATUSES_TO_SCHEDULE_APPOINTMENT = [
  MEMBER_SERVICE_STATUS.REFERRAL_SENT,
  MEMBER_SERVICE_STATUS.REPEAT_REFERRAL_SENT,
  MEMBER_SERVICE_STATUS.SIGNED_UP,
  MEMBER_SERVICE_STATUS.ONBOARDED,
  MEMBER_SERVICE_STATUS.SURVEY_STARTED,
  MEMBER_SERVICE_STATUS.SURVEY_COMPLETED,
  MEMBER_SERVICE_STATUS.APPOINTMENT_PAYMENT_PENDING,
  MEMBER_SERVICE_STATUS.APPOINTMENT_PAYMENT_FAILED,
  MEMBER_SERVICE_STATUS.SURVEY_NOT_REQUIRED
];
