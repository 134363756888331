import { api } from "@/apps/common/api-client";
import { useRequest } from "ahooks";
import FeatherIcon from "feather-icons-react";
import Markdown from "markdown-to-jsx";
import React, { PropsWithChildren, useCallback } from "react";
import {
  Accordion,
  Button,
  ButtonGroup,
  Card,
  Col,
  ListGroup
} from "react-bootstrap";

function findTextInReactChildren(
  children: React.ReactNode,
  text: string
): boolean {
  return React.Children.toArray(children).some((child) => {
    if (React.isValidElement(child)) {
      return findTextInReactChildren(child.props.children, text);
    }
    return String(child).includes(text);
  });
}

function SelectableListItem({
  selectedCode,
  onClick,
  children
}: PropsWithChildren<{ selectedCode: string; onClick: () => void }>) {
  console.log({
    selectedCode,
    children: React.Children.toArray(children).map(String)
  });
  return (
    <ListGroup.Item
      action
      onClick={onClick}
      active={findTextInReactChildren(children, selectedCode)}
    >
      {children}
    </ListGroup.Item>
  );
}

export default function AppointmentDiagnosisAid({
  appointmentId,
  onDiagnosisCodeSelected
}: {
  appointmentId: number;
  onDiagnosisCodeSelected: (code: string) => void;
}) {
  const { data: aids = [], refresh: refreshDiagnosisAid } = useRequest(
    () => api.diagnosisAid.getByAppointment(Number(appointmentId)),
    {
      pollingInterval: 5000
    }
  );

  const latestAid = aids[0];
  const latestDiagnosisAidId = latestAid?.id;

  const rateSummary = useCallback(
    async (rating: number) => {
      await api.diagnosisAid.rateDiagnosis(latestDiagnosisAidId, rating);
      refreshDiagnosisAid();
    },
    [latestDiagnosisAidId, refreshDiagnosisAid]
  );

  const selectDiagnosisCode = useCallback(
    async (event: any) => {
      const code = event.target.innerText.split(":")[0];
      await api.diagnosisAid.selectDiagnosisCode(latestDiagnosisAidId, code);
      refreshDiagnosisAid();

      const cptCode = code.split(" ")[0]; // Could use regex here
      onDiagnosisCodeSelected(cptCode);
    },
    [latestDiagnosisAidId, onDiagnosisCodeSelected, refreshDiagnosisAid]
  );

  if (!latestAid?.possibleDiagnoses) {
    return null;
  }

  return (
    <Col>
      <Card>
        <Card.Body>
          <Card.Title as={"h4"}>Diagnosis Aid</Card.Title>
          <Accordion>
            {latestAid.counselorSuggestions ? (
              <Accordion.Item eventKey="counselorSuggestions">
                <Accordion.Header>Counselor Suggestions</Accordion.Header>
                <Accordion.Body>
                  <Markdown>{latestAid.counselorSuggestions}</Markdown>
                </Accordion.Body>
              </Accordion.Item>
            ) : null}
            {latestAid.possibleDiagnoses ? (
              <Accordion.Item eventKey="possibleDiagnoses">
                <Accordion.Header>Possible Diagnoses</Accordion.Header>
                <Accordion.Body>
                  <ButtonGroup className="me-2" aria-label="Rate the diagnoses">
                    <Button
                      variant="secondary"
                      active={latestAid.diagnosesRating === 1}
                      onClick={() => rateSummary(1)}
                    >
                      <FeatherIcon icon={"thumbs-up"} />
                    </Button>
                    <Button
                      variant="secondary"
                      active={latestAid.diagnosesRating === -1}
                      onClick={() => rateSummary(-1)}
                    >
                      <FeatherIcon icon={"thumbs-down"} />
                    </Button>
                  </ButtonGroup>
                  <div>
                    <Markdown>{latestAid.possibleDiagnoses}</Markdown>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ) : null}
            {latestAid.diagnosisCodeSuggestions ? (
              <Accordion.Item eventKey="diagnosisCodeSuggestions">
                <Accordion.Header>Suggested Diagnosis Code</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <Markdown
                    // options={{
                    //   overrides: {
                    //     ul: {
                    //       component: ListGroup
                    //     },
                    //     li: {
                    //       component: SelectableListItem,
                    //       props: {
                    //         onClick: selectDiagnosisCode,
                    //         selectedCode: latestAid.selectedDiagnosisCode
                    //       }
                    //     }
                    //   }
                    // }}
                    >
                      {latestAid.diagnosisCodeSuggestions!}
                    </Markdown>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ) : null}
          </Accordion>
        </Card.Body>
      </Card>
    </Col>
  );
}
