import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {encode} from 'html-entities';
import FeatherIcons from "feather-icons-react";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useLocation, useSearchParams } from "react-router-dom";

import { FormInput, InputMasks, VerticalForm } from "@/apps/common/components/";
import { StateType } from "@/apps/common/types/appointment-types";
import StateSelector from "@/apps/provider/pages/Profile/StateSelector";
//import QualificationSelector from "@/apps/provider/pages/Profile/QualificationSelector";
import { PortalTypes } from "@/constants/portals";
import { getPortalLink, getPortalType } from "../../helpers";
import {
  DATE_FORMATS,
  getUserTimezone,
  isValidDateFormat,
  reverseFormatDateOfBirth
} from "../../helpers/date";
import { useTitle } from "../../helpers/useTitle";
import { useAuthStore } from "../../store/useAuthStore";
import AuthLayout from "./AuthLayout";
import mixpanel from "mixpanel-browser";
import config from "@/config";
import { QualificationType } from "../../types/appointment-types";
import ProviderProfileForm from "@/apps/common/components/ProviderProfile"
import { api } from "@/apps/common/api-client";

// images
type _state = {
  id: number;
  attributes: {
    name: string;
    code: string;
    createdAt: string;
    updatedAt: string;
    enabled: boolean;
  };
};

interface UserData {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  state: string;
  spokenLanguage: string;
  email: string;
}

interface ClinicData {
  clinicName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  state: string;
  address: string;
}

interface ServiceProviderData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  state: string;
  timezone: string;
  dateOfBirth?: string;
  address: string;
  //qualifications: QualificationType[];
  type?: string;
  languages: [];
  npi?: string;
  states: StateType[];
  serviceTypes: [];
  service_provider_types: []
  qualification?: string;
}

type FormData = UserData | ClinicData | ServiceProviderData;

interface UserAuthData {
  email: string;
  password: string;
}

/* bottom links */
const BottomLink = () => {
  const { t } = useTranslation();

  return (
    <Row className="mt-3">
      <Col xs={12} className="text-center">
        <p className="text-muted">
          {t("Already have account?")}{" "}
          <Link to={"/auth/login"} className="text-primary fw-bold ms-1">
            {t("Login")}
          </Link>
        </p>
      </Col>
    </Row>
  );
};

const Register = () => {
  const [selectedStates, setSelectedStates] = useState<StateType[]>([]);
  const [selectedtQualifications, setSelectedQualifications] = useState<
    QualificationType[]
  >([]);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const token = searchParams.get("token");
  const { t } = useTranslation();
  const {
    fetchStates,
    register,
    registerClinic,
    registerServiceProvider,
    user,
    loggedIn,
    states
  } = useAuthStore();
  const [authStep, setAuthStep] = useState(token ? 2 : 1);
  const [userData, setUserData] = useState<UserData>({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    phoneNumber: "",
    state: "",
    spokenLanguage: "en",
    email: '',
  });
  const [clinicData, setClinicData] = useState<ClinicData>({
    clinicName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    state: "",
    address: ""
  });

  const [providerProfileData, setProviderProfileData] = useState<any>();

  const [serviceProviderData, setServiceProviderData] =
    useState<ServiceProviderData>({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      state: "",
      address: "",
      dateOfBirth: "",
      //qualifications: [],
      type: "licensed_therapist",
      timezone: "",
      states: [],
      languages: [],
      serviceTypes: [],
      service_provider_types: []
    });

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useTitle("Register");

  /*
   * form validation schema
   */
  const userSchemaResolver = yupResolver(
    yup.object().shape({
      firstName: yup.string().required(t("Please enter First name")),
      lastName: yup.string().required(t("Please enter Last name")),
      dateOfBirth: yup
        .string()
        .required(t("Please enter Date of birth"))
        .test("is-valid-date", "Please enter date in MM/DD/YYYY", (value) => {
          return isValidDateFormat(value, DATE_FORMATS.DATE_ONLY);
        }),

      phoneNumber: yup.string().required("Please enter Phone"),
      state: yup.string().required("Please select your State"),
      spokenLanguage: yup.string().required("Please select your Language")
    })
  );

  const clinicSchemaResolver = yupResolver(
    yup.object().shape({
      clinicName: yup.string().required("Please enter Clinic name"),
      firstName: yup.string().required(t("Please enter First name")),
      lastName: yup.string().required(t("Please enter Last name")),
      phoneNumber: yup.string().required("Please enter Phone"),
      state: yup.string().required("Please select your State"),
      address: yup.string().required("Please enter address")
    })
  );
  const serviceProviderSchemaResolver = yupResolver(
    yup.object().shape({
      firstName: yup.string().required(t("Please enter First name")),
      lastName: yup.string().required(t("Please enter Last name")),
      phoneNumber: yup.string().required("Please enter Phone"),
      //state: yup.string().required("Please select your State"),
      address: yup.string().required("Please enter address"),
      // type: yup
      //   .string()
      //   .required("Please select the type of service you provide"),
      // qualification: yup.string().required("Please enter your qualifications"),
      lic: yup.array().optional()
    })
  );
  const authSchemaResolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .required("Please enter Email")
        .email("Please enter valid Email"),
      password: yup.string().required(t("Please enter Password")),
      checkboxsignup: yup.bool().oneOf([true])
    })
  );

  const onSubmitProfileData = (formData: any) => {

    //console.debug('Provider Profile Data ' + JSON.stringify(formData))
    setProviderProfileData(formData)
    const userData: UserData = {
      firstName: encode(formData?.firstName) ? encode(formData?.firstName) : '',
      lastName: encode(formData?.lastName) ? encode(formData?.lastName) : '',
      dateOfBirth: '',
      phoneNumber: formData?.phoneNumber ? formData?.phoneNumber : null,
      email: formData?.email ? formData?.email : null,
      state: formData?.state ? formData?.state : 5,
      spokenLanguage: formData?.spokenLanguage ? formData?.spokenLanguage : 'en',

    }

    setUserData(userData);

    console.debug (`formData - ${JSON.stringify(formData)}`)
    const _serviceProviderData: ServiceProviderData = {

      firstName: encode(formData?.firstName) ? encode(formData?.firstName) : '',
      lastName: encode(formData?.lastName) ? encode(formData?.lastName) : '',
      dateOfBirth: '',
      phoneNumber: formData?.phoneNumber ? formData?.phoneNumber : null,
      email: formData?.email ? formData?.email : null,
      state: formData?.state ? formData?.state : 5,
      npi: formData.npi,
      timezone: "",
      states: formData?.states ? formData?.states : [],
      languages: formData?.languages ? formData?.languages : [],
      serviceTypes: formData?.serviceTypes ? formData?.serviceTypes : [],
      service_provider_types: formData?.service_provider_types ? formData?.service_provider_types : [],
      address: formData?.address ? formData?.address : '',
      type: formData?.type ? formData?.type : 'licensed_therapist',
      qualification: formData?.qualification ? formData?.qualification : ''
    }

    setServiceProviderData(_serviceProviderData)

    //console.debug('UserData' + JSON.stringify(userData))

    setAuthStep(2)

  }

  const onSubmit = (formData: FormData) => {

    formData.firstName = encode(formData.firstName)
    formData.lastName = encode(formData.lastName)
    formData.state = encode (formData.state)


    if (getPortalType() === PortalTypes.CLINICAL) {
      setClinicData(formData as ClinicData);
    } else if (getPortalType() === PortalTypes.MEMBER) {
      
      setUserData(formData as UserData);
    } else if (getPortalType() === PortalTypes.PROVIDER) {
      //console.debug("Form Data - " + JSON.stringify(formData));
      //console.debug("selected state - " + JSON.stringify(selectedStates));
      const spData: ServiceProviderData = formData as ServiceProviderData;
      spData.state = selectedStates[0].id.toString();
      spData.states = selectedStates;
      //spData.qualifications = selectedtQualifications;
      //console.debug ('qualifications - ' + JSON.stringify (selectedtQualifications))
      //spData.type = 'licensed-therapist'
      setServiceProviderData(spData);
    }

    setAuthStep(2);
  };

  const onSubmitAuth = async (formData: UserAuthData) => {

    console.debug (`Encoded formData - ${JSON.stringify(formData)}`)
    try {
      if (config.MIXPANEL_TOKEN) {
        mixpanel.track("Submit Signup Form");
      }

      setLoading(true);
      switch (getPortalType()) {
        case PortalTypes.PROVIDER: {
          //states: selected_states.length > 0 ? selected_states : serviceProviderData.states!
          console.debug(
            "registerServiceProvider - " + JSON.stringify(serviceProviderData)
          );
          const resp = await registerServiceProvider({
            payload: {
              ...serviceProviderData,
              username: formData.email,
              ...formData,
              dateOfBirth: "1900-01-01" // This is a bit dumb since the DOB is a required field on user,
            }
          });
          // console.debug('user id ' + JSON.stringify(resp.data.id))
          const args = {
            populate: ['user'],
            filters: {
              user: resp.data.id
            }
          }

          const sp = await api.serviceProviders.findMany(args).then(
            (res) => {

              //console.debug('Provider Data - ' + JSON.stringify(serviceProviderData))
              //delete serviceProviderData.type;
              //serviceProviderData.type = res[0]?.type
              //serviceProviderData.type 
              if ((serviceProviderData.type !== 'mental-health-coach') && (serviceProviderData.type !== 'health-wellness-peer'))
                delete serviceProviderData.type

              console.debug(`res - ${JSON.stringify(serviceProviderData)}`)

              api.serviceProviders.update(res[0].id, serviceProviderData).then(
                (res) => {
                  if (res.id !== undefined) {
                    api.serviceProviders.find(res.id)?.then(
                      (resData) => {
                        //console.debug('successfully updated service provider profile - ' + JSON.stringify(resData))
                        //setSubmitMessage("Provider details were updated successfully !")
                        //showAlert()
                        //updateData(resData)
                        // let a: Partial<ServiceProviderProfileType>
                        // a = {
                        //   ...resData
                        // }
                        // setServiceProviderData(resData)
                      }
                    )
                  }
                },
                (err) => {
                  console.debug('error updating service provider profile - ' + JSON.stringify(err))
                  //setSubmitMessage("An error occured while updating the Provider details. Please try again.")
                  //showAlert()
                }
              )
            }

          )


          // Find the service provider that belongs to this user id
        }
          break;
        case PortalTypes.MEMBER: {
          await register({
            payload: {
              username: formData.email,
              ...(!token && {
                ...userData,
                dateOfBirth: reverseFormatDateOfBirth(userData.dateOfBirth)!
              }),
              ...formData,
              ...(token && { token }),
              timezone: getUserTimezone()
            }
          });
        }
          break;
        case PortalTypes.CLINICAL: {
          await registerClinic({
            payload: {
              username: formData.email,
              ...formData,
              ...clinicData,
              clinic: {
                name: clinicData.clinicName,
                location: clinicData.address,
                state: clinicData.state
              },
              timezone: getUserTimezone()
            }
          });
        }
          break;
      }
      if (config.MIXPANEL_TOKEN) {
        mixpanel.track("Signup Success");
      }
      setLoading(false);
    } catch (err) {
      console.debug("Error while submitting form - " + JSON.stringify(err));
      const errResponse = (
        err as { response?: { data?: { error?: { message?: string } } } }
      ).response;
      let errMessage = errResponse?.data?.error?.message || "";
      if (errMessage === "Email or Username are already taken") {
        errMessage = t("This email account already exists.");
      }
      setError(errMessage);
      console.error("err", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStates();
  }, [fetchStates]);

  const redirectUrl = location?.search?.slice(6) || "/";

  return (
    <>
      {loggedIn || user ? <Navigate to={redirectUrl}></Navigate> : null}

      <AuthLayout bottomLinks={<BottomLink />}>
        <h4 className="h4 mb-3 mt-2">{t("Create your account")}</h4>

        {error && (
          <Alert variant="danger" className="my-2">
            {error}
          </Alert>
        )}

        {getPortalType() === PortalTypes.CLINICAL && (
          <Alert className="fw-bold" variant="warning">
            This sign up is for clinics only. If you are a patient then please
            signup here:{" "}
            <a
              href={`${getPortalLink(PortalTypes.MEMBER)}`}
              className="text-primary fw-bold"
            >
              {t("Member Signup")}
            </a>
          </Alert>
        )}

        {(authStep === 1) && (getPortalType() === PortalTypes.PROVIDER) ?

          (
            <>
              <ProviderProfileForm mode={"register"} serviceProvider={null} updateData={() => { }} providerProfileFormData={onSubmitProfileData} />
            </>
          ) : null


        }

        {((authStep === 1) && (getPortalType() !== PortalTypes.PROVIDER)) && (
          <VerticalForm<FormData>
            onSubmit={onSubmit}
            resolver={
              getPortalType() === PortalTypes.CLINICAL
                ? (clinicSchemaResolver as any)
                : getPortalType() === PortalTypes.PROVIDER
                  ? (serviceProviderSchemaResolver as any)
                  : (userSchemaResolver as any)
            }
            defaultValues={
              {
                [PortalTypes.CLINICAL]: clinicData,
                [PortalTypes.PROVIDER]: serviceProviderData,
                [PortalTypes.MEMBER]: userData,
                [PortalTypes.ADMIN]: userData,
                [PortalTypes.PARTNER]: userData
              }[getPortalType()]
            }
            formClass="authentication-form row"
          >
            {getPortalType() === PortalTypes.CLINICAL && (
              <FormInput
                label={t("Clinic name")}
                type="text"
                name="clinicName"
                startIcon={
                  <FeatherIcons icon={"activity"} className="icon-dual" />
                }
                placeholder={t("Clinic name")}
                containerClass={"mb-2 col-sm-12 col-md-12 col-lg-12"}
              />
            )}
            <FormInput
              label={t("First name")}
              type="text"
              name="firstName"
              startIcon={<FeatherIcons icon={"user"} className="icon-dual" />}
              placeholder={t("Your first name")}
              containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
            />
            <FormInput
              label={t("Last name")}
              type="text"
              name="lastName"
              startIcon={<FeatherIcons icon={"user"} className="icon-dual" />}
              placeholder={t("Your last name")}
              containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
            />
            {getPortalType() === PortalTypes.MEMBER && (
              <InputMasks
                type="mask"
                startIcon={
                  <FeatherIcons icon={"calendar"} className="icon-dual" />
                }
                label={t("Date of birth")}
                mask={[
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
                placeholder="MM/DD/YYYY"
                name="dateOfBirth"
                containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
              />
            )}
            <InputMasks
              type="mask"
              startIcon={<FeatherIcons icon={"phone"} className="icon-dual" />}
              label={t("Mobile Phone")}
              mask={[
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/
              ]}
              placeholder="(___) ___-____"
              name="phoneNumber"
              containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
            />
            {getPortalType() === PortalTypes.MEMBER && (
              <FormInput
                label={t("Language Spoken")}
                type="select"
                name="spokenLanguage"
                key="spokenLanguage"
                startIcon={
                  <FeatherIcons icon={"globe"} className="icon-dual" />
                }
                containerClass={"mb-3 col-sm-12 col-md-12 col-lg-12"}
              >
                <option value="">Select your language</option>
                <option value="en">English</option>
                <option value="es">Spanish</option>
              </FormInput>
            )}
            {(getPortalType() === PortalTypes.CLINICAL) && (
              <FormInput
                label={t("Address")}
                type="text"
                name="address"
                startIcon={
                  <FeatherIcons icon={"map-pin"} className="icon-dual" />
                }
                placeholder={t("Your address")}
                containerClass={"mb-2 col-sm-12 col-md-12 col-lg-12"}
              />
            )}
            {(getPortalType() === PortalTypes.CLINICAL ||
              getPortalType() === PortalTypes.MEMBER) && (
                <FormInput
                  label={t("State")}
                  type="select"
                  key="state"
                  name="state"
                  startIcon={
                    <FeatherIcons icon={"map-pin"} className="icon-dual" />
                  }
                  containerClass={`mb-2 col-sm-12 col-md-12 col-lg-12"`}
                  defaultValue={""}
                >
                  <option value="">Select your state</option>
                  {/* {states &&
                  states.map((v: StateType, i: number) => (
                    <option key={i} value={v?.id}>
                      {v?.attributes?.name}
                    </option>
                  ))} */}
                  {states &&
                    states.map((v: _state, i: number) => (
                      <option key={i} value={v.id}>
                        {v?.attributes?.name}
                      </option>
                    ))}
                </FormInput>
              )}
            {/* {getPortalType() === PortalTypes.PROVIDER && (
              <FormInput
                label={t("Type of Service Provider")}
                type="select"
                key="type"
                name="type"
                startIcon={
                  <FeatherIcons icon={"users"} className="icon-dual" />
                }
                containerClass={`mb-2 col-sm-12 col-md-12 ${getPortalType() === PortalTypes.MEMBER || PortalTypes.PROVIDER
                  ? "col-lg-12"
                  : "col-lg-6"
                  }`}
                defaultValue={"licensed-therapist"}
              >
                <option value="">Type of Service Provider</option>
                <option value="licensed-therapist">Licensed Therapist</option>
                <option value="psychologist">Psychologist</option>
              </FormInput>
            )} */}

            {/* {getPortalType() === PortalTypes.PROVIDER && (
              <FormInput
                label={t("Qualifications")}
                type="text"
                name="qualification"
                startIcon={
                  <FeatherIcons icon={"award"} className="icon-dual" />
                }
                placeholder={t("Qualification")}
                containerClass={"mb-2 col-sm-12 col-md-12 col-lg-12"}
              />
            )} */}
            {/* 
            {getPortalType() === PortalTypes.PROVIDER && (
              <Form.Group className={"mb-2 col-sm-12 col-md-12 col-lg-12"}>
                <Form.Label>Type of Service Provider</Form.Label>
                
              </Form.Group>
            )} */}

            {/* {getPortalType() === PortalTypes.PROVIDER && (
              <Form.Group className={"mb-2 col-sm-12 col-md-12 col-lg-12"}>
                <Form.Label>States where you are licensed</Form.Label>
                <StateSelector
                  name="lic_states"
                  value={serviceProviderData.lic_states}
                  onChange={setSelectedStates}
                />
              </Form.Group>
            )} */}

            <div className="mb-2 text-center d-grid col">
              <Button type="submit">{t("Continue")}</Button>
            </div>
          </VerticalForm>
        )}

        {authStep === 2 && (
          <VerticalForm<UserAuthData>
            onSubmit={onSubmitAuth}
            resolver={authSchemaResolver}
            defaultValues={{ email: userData.email, password: null }}
            formClass="authentication-form row"
          >
            <FormInput
              label={t("Email Address")}
              type="email"
              name="email"
              startIcon={<FeatherIcons icon={"mail"} className="icon-dual" />}
              placeholder={t("hello@domain.com")}
              containerClass={"mb-2 col-12"}
            />
            <FormInput
              label={t("Password")}
              type="password"
              name="password"
              startIcon={<FeatherIcons icon={"lock"} className="icon-dual" />}
              placeholder={t("Enter your Password")}
              containerClass={"mb-3 col-12"}
            />
            <FormInput
              element={
                <span className="fs-10">
                  Please check this box to acknowledge that you have read and
                  accept the{" "}
                  <a
                    href="https://skylerhealth.com/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{" "}
                  and the{" "}
                  <a href="https://skylerhealth.com/tou" target="_blank">
                    Terms of Use
                  </a>
                </span>
              }
              type="checkbox"
              name="checkboxsignup"
              containerClass={"mb-2 col-12"}
            />
            {!token && (
              <div className="mb-2 text-center d-grid col-12">
                <Button
                  onClick={() => setAuthStep(1)}
                  variant="soft-secondary"
                  type="submit"
                >
                  {t("Back")}
                </Button>
              </div>
            )}
            <div className="mb-2 text-center d-grid col-12">
              <Button type="submit" disabled={loading}>
                {loading ? t("Loading...") : t("Sign Up")}
              </Button>
            </div>
          </VerticalForm>
        )}
      </AuthLayout>
    </>
  );
};

export default Register;
